// @ts-nocheck
import { getTime } from "date-fns"
import Highcharts from "highcharts/highstock"
import * as R from "ramda"

import { lightenDarkenColor } from "../../../../../../utils/lightenDarkenColor"

export const formatData = (colTitles, visibleRowData, rowDataWithoutTitle) => {
  return visibleRowData.map((row, index) => {
    const startDateOfForecast = R.compose(R.head, R.pathOr([], ["extra", "forecasts"]))(row)

    /**
     * getTime returns Unix time in ms and Hightcharts required Unix time in ms.
     * So we use getTime here.
     * There is also getUnixTime but returns Unix time in second.
     */
    const unixStartDateOfForecast =
      startDateOfForecast &&
      getTime(new Date(R.find(R.propEq("field", startDateOfForecast), colTitles).end))

    const pct = row.extra.frontOpts.pct ? row.extra.frontOpts.pct : false

    /**
     * Default 'chartType' option is spline for pct series and column for others series.
     * ,User can override Default by his own choice between spline, line and histogram (=column).
     */
    const type = row.extra.frontOpts.chartType
      ? row.extra.frontOpts.chartType
      : pct
        ? "spline"
        : "column"

    const data = R.compose(
      R.sortBy(R.prop(0)),
      R.values(),
      R.mapObjIndexed((obj, key) => [
        getTime(new Date(R.find(R.propEq("field", key), colTitles).end)), // hightcharts required unix time in ms.
        pct ? Number(R.propOr(null, "value", obj)) * 100 : Number(R.propOr(null, "value", obj)),
      ]),
      R.omit(["extra"]),
    )(row)

    const dashStyle = row.extra.frontOpts.chartDashStyle
      ? row.extra.frontOpts.chartDashStyle
      : "solid"

    /**
     * To generate chart colors, we're using "Highcharts.getOptions().colors[indexForColor]".
     * Highcharts has 10 automatic colors, so we need to take only the 1st digit of indexOfRow and use it as indexForColor.
     */

    const indexOfRow = R.findIndex(R.pathEq(["extra", "rowId"], row.extra.rowId))(
      rowDataWithoutTitle,
    )
    const indexForColor = Math.floor((indexOfRow / 1) % 10) // Math.floor retrives the 1st digit of indexOfRow.
    const color = row.extra.frontOpts.chartColor
      ? row.extra.frontOpts.chartColor
      : Highcharts.getOptions().colors[indexForColor] // colors generate by highcharts

    /**
     * 'zones' are used to separate report from forecast.
     *  'value' is the start date of the zone, so here 'value' is the date of the beginning of the forecast
     *  LightenDarkenColor is a simple JavaScript helper which allow darken or lighten a color.
     */

    return {
      type,
      name: row.extra.header,
      yAxis: row.extra.frontOpts.chartOwnAxis ? index + 1 : 0,
      pct,
      zoneAxis: "x",
      zones: [
        { value: unixStartDateOfForecast },
        {
          color: lightenDarkenColor(color, 60),
        },
      ],
      color,
      data,
      dashStyle,
      tooltip: {
        headerFormat: "{point.x:%d-%m-%Y}",
        pointFormat: pct ? "{series.name}: {point.y}%" : "{series.name}: {point.y}",
        valueDecimals: pct ? 2 : 0,
      },
    }
  })
}
