/** @jsxImportSource @emotion/react */
import { Button, Tooltip } from "@blueprintjs/core"
import { Dispatch, SetStateAction, useCallback, useMemo } from "react"

import { FlexContainer } from "../../../styled/flexbox.styled"
import { roundNumber } from "../../_shared"
import { ConsensusTooltipContent, TooltipContent } from "./MetricUpdateTooltips"
import {
  ConMetricPercent,
  MetricContainer,
  MetricName,
  MetricPercent,
  NameContainer,
  NoDataIcon,
  PercentContainer,
  PipeSeparator,
  metricNameButtonCss,
} from "./MetricUpdate.styled"
import { FieldType, UpdatedMetric } from "./types/business"
import { MediumText } from "../../../styled/text.styled"
import { SCIENT_COLORS } from "../../../styled/scientColors"

interface IMetricUpdate {
  setOpenChartDialog: Dispatch<SetStateAction<boolean>>
  setSelectedPeriod: Dispatch<SetStateAction<string>>
  setSelectedMetric: Dispatch<SetStateAction<string>>
  setSelectedUpdate: Dispatch<SetStateAction<UpdatedMetric | null>>
  wrapping: boolean
  updatedMetric: UpdatedMetric
  metricsIsInCollapse?: boolean
  period: string
  inChartDialog: boolean
}

const MetricUpdate = ({
  setOpenChartDialog,
  setSelectedPeriod,
  setSelectedMetric,
  setSelectedUpdate,
  wrapping,
  updatedMetric,
  metricsIsInCollapse,
  period,
  inChartDialog,
}: IMetricUpdate) => {
  const isBpsMetric = useMemo(
    () => [FieldType.OSG, FieldType.PROFIT_MARGIN].includes(updatedMetric.metric),
    [updatedMetric],
  )

  const isMetricComparedToCon = useMemo(
    () =>
      [FieldType.KEY_PROFIT, FieldType.OSG, FieldType.PROFIT_MARGIN, FieldType.EBIT].includes(
        updatedMetric.metric,
      ),
    [updatedMetric],
  )

  const handleClick = useCallback(() => {
    setOpenChartDialog(true)
    setSelectedPeriod(period)
    setSelectedMetric(updatedMetric.metric)
    setSelectedUpdate(updatedMetric)
  }, [
    period,
    setOpenChartDialog,
    setSelectedMetric,
    setSelectedPeriod,
    setSelectedUpdate,
    updatedMetric,
  ])

  /**
   * signifiantChangeInValue is a boolean representing if there is a significant change in the value of a metric.
   * If yes, 'updatedMetric.value_change' is a number representing the variation, else it's null.
   * updatedMetric object has always the key 'value_change', even if null, in order to keep
   * the consistency of the list of Updated Metric objects.
   */
  const signifiantChangeInValue = useMemo(
    () => (updatedMetric.value_change ? true : false),
    [updatedMetric.value_change],
  )

  /**
   * metricHasConsensusDiff is a boolean telling if a metric has a consensus difference.
   */
  const metricHasConsensusDiff = useMemo(
    () => (updatedMetric.consensus_data?.consensus_diff ? true : false),
    [updatedMetric.consensus_data?.consensus_diff],
  )

  return (
    <MetricContainer
      alignItems="center"
      flexWrap="nowrap"
      justifyContent="flex-start"
      isMetricComparedToCon={isMetricComparedToCon}
      inChartDialog={inChartDialog}
    >
      <NameContainer
        justifyContent="flex-start"
        metricName={updatedMetric.metric}
        inChartDialog={inChartDialog}
      >
        {!inChartDialog ? (
          (!metricsIsInCollapse || (metricsIsInCollapse && wrapping)) && ( // if metrics are in collapse and metrics is not wrapping, metric's name is hidden.
            <Button
              minimal
              small
              onClick={handleClick}
              disabled={!signifiantChangeInValue}
              css={metricNameButtonCss(signifiantChangeInValue)}
            >
              <MetricName signifiantChangeInValue={signifiantChangeInValue}>
                {updatedMetric.metric_name || updatedMetric.metric}
              </MetricName>
            </Button>
          )
        ) : (
          //Display Metric name in the chart modal.
          <MetricName signifiantChangeInValue={signifiantChangeInValue}>
            {updatedMetric.metric}
          </MetricName>
        )}
      </NameContainer>

      <FlexContainer gap="10px" alignItems="center">
        <Tooltip content={TooltipContent({ updatedMetric })} placement="bottom" compact>
          <PercentContainer justifyContent={signifiantChangeInValue ? "flex-end" : "center"}>
            {!updatedMetric.value_change ? (
              <NoDataIcon>--</NoDataIcon>
            ) : (
              <MetricPercent valueChange={updatedMetric.value_change}>
                {updatedMetric.value_change > 0 ? "+" : ""}
                {isBpsMetric
                  ? `${roundNumber(updatedMetric.value_change, 0)} bps`
                  : `${roundNumber(updatedMetric.value_change)} %`}
              </MetricPercent>
            )}
          </PercentContainer>
        </Tooltip>
        {isMetricComparedToCon && (
          <PipeSeparator metricHasConsensusDiff={metricHasConsensusDiff}>|</PipeSeparator>
        )}

        <Tooltip content={ConsensusTooltipContent({ updatedMetric })} placement="bottom" compact>
          {isMetricComparedToCon ? (
            <FlexContainer
              justifyContent={!updatedMetric.consensus_data?.consensus_diff ? "center" : "flex-end"}
            >
              {!updatedMetric.value_change ||
              !updatedMetric.consensus_data ||
              !updatedMetric.consensus_data.consensus_diff ? (
                <NoDataIcon>-- vs CON</NoDataIcon>
              ) : (
                <ConMetricPercent valueChange={updatedMetric.consensus_data.consensus_diff}>
                  {updatedMetric.consensus_data.consensus_diff > 0 ? "+" : ""}
                  {isBpsMetric
                    ? `${roundNumber(updatedMetric.consensus_data.consensus_diff, 0)} bps vs CON`
                    : `${roundNumber(updatedMetric.consensus_data.consensus_diff)} % vs CON`}
                </ConMetricPercent>
              )}
            </FlexContainer>
          ) : (
            <></>
          )}
        </Tooltip>
      </FlexContainer>
    </MetricContainer>
  )
}
export default MetricUpdate
