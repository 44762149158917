import { useState } from "react"
import { IUser } from "../../../api/index"
import { FlexContainer } from "../../../styled/flexbox.styled"
import ModelUpdates from "./ModelUpdates"
import { NestedNocContainer, NocContainer } from "./Noc.styled"
import NocFilters from "./filtering/NocFilters"
import { FieldType } from "./types/business"
/**
 * Notifications center
 * For now only one kind of notifications: models updates.
 * Later we will have more kinds of notifications.
 */

const Noc = () => {
  const [userFilter, setUserFilter] = useState<IUser | null>(null)
  const [stockFilter, setStockFilter] = useState<{ id: number; bloomberg_code: string } | null>(
    null,
  )
  const [companyFilter, setCompanyFilter] = useState<{ id: number; name: string } | null>(null)
  const [sectorFilter, setSectorFilter] = useState<{ id: number; name: string } | null>(null)
  const [seenMetrics, setSeenMetrics] = useState<Array<FieldType>>([
    FieldType.KEY_PROFIT,
    FieldType.OSG,
    FieldType.PROFIT_MARGIN,
    FieldType.SALES,
    FieldType.EBITDA,
  ])
  return (
    <NocContainer flexDirection="column" id="scrollableContainer">
      <FlexContainer justifyContent="center">
        <NestedNocContainer flexDirection="column">
          <NocFilters
            userFilter={userFilter}
            stockFilter={stockFilter}
            companyFilter={companyFilter}
            sectorFilter={sectorFilter}
            setUserFilter={setUserFilter}
            setStockFilter={setStockFilter}
            setCompanyFilter={setCompanyFilter}
            setSectorFilter={setSectorFilter}
            seenMetrics={seenMetrics}
            setSeenMetrics={setSeenMetrics}
          />
          <ModelUpdates
            stockParam={stockFilter?.id}
            userParam={userFilter?.id}
            companyParam={companyFilter?.id}
            sectorParam={sectorFilter?.id}
            seenMetrics={seenMetrics}
          />
        </NestedNocContainer>
      </FlexContainer>
    </NocContainer>
  )
}

export default Noc
