/** @jsxImportSource @emotion/react */
import { CSSProperties, useEffect, useState } from "react"
import { UseControllerProps, useController, useFormContext } from "react-hook-form"

import { ErrorIconWithTooltip } from "../../Trading/components/ErrorIconWithTooltip"
import { getNumberValidationRegex } from "../../utils/getNumberValidationRegex"
import { RebalancingWithTargets } from "../types"
import { CustomInput } from "./Input.styled"

interface IPercentageInputProps extends UseControllerProps<RebalancingWithTargets> {
  index: number
  displayedDecimal?: number
  allowNegativeValues?: boolean
  onlyNegativeValues?: boolean
  textAlign?: CSSProperties["textAlign"]
  onEnter?: (index: number) => void
}

/**
 * Specific Input for use in Rebalancing form, where suffix '%' is add onBlur.
 * It takes value, format to 1 decimal place, add suffix " %" and display it.
 * onChange, value is stored as string
 */

export const PercentageInput = ({
  index,
  allowNegativeValues = false,
  onlyNegativeValues = false,
  displayedDecimal = 0,
  control,
  name,
  rules,
  textAlign,
  onEnter,
}: IPercentageInputProps) => {
  const [displayValue, setDisplayValue] = useState<string>("")

  const {
    field: { ref, value, onChange },
    fieldState,
  } = useController({ control, name, rules })

  const { resetField, setValue, watch } = useFormContext()

  // Get path of tracking keys
  const editedKey = name.replace(/value$/, "edited")
  const adjustedKey = name.replace(/value$/, "adjusted")

  // Get tracking keys
  const isEdited = watch(editedKey)
  const isAdjusted = watch(adjustedKey)

  // Sync display value with form value
  useEffect(() => {
    if (!value) {
      setDisplayValue("")
    } else {
      setDisplayValue(`${(parseFloat(value as string) * 100).toFixed(displayedDecimal)} %`)
    }
  }, [value, displayedDecimal])

  // Restrict characters to number
  const allowedCharacters = getNumberValidationRegex(
    allowNegativeValues,
    onlyNegativeValues,
    displayedDecimal,
  )

  // define the allowed special keys
  const specialKeys = ["Tab", "Backspace", "Delete", "ArrowLeft", "ArrowRight", "ArrowUp"]

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (allowedCharacters.test(inputValue)) {
      setDisplayValue(inputValue)
    }
  }

  // Format on blur with decimal place defined and '%' suffix and store value in form state
  const handleBlur = () => {
    const numericDisplayValue = parseFloat(displayValue)
    if (!isNaN(numericDisplayValue)) {
      const formattedDisplayValue = numericDisplayValue.toFixed(displayedDecimal)
      setDisplayValue(`${formattedDisplayValue} %`)

      // Format the current value from the form state for comparaison
      const formattedStateValue =
        value !== null ? (parseFloat(value as string) * 100).toFixed(displayedDecimal) : ""

      // Only update if the value has changed
      if (formattedStateValue !== displayValue) {
        // Format numericDisplayValue to be store in form state
        const newValue = (numericDisplayValue / 100).toFixed(displayedDecimal + 2) // decimal + 2 because we divide by 100
        onChange(newValue)
        setValue(editedKey, true, { shouldDirty: false })
      }
    } else {
      resetField(name, { keepDirty: false })
      setDisplayValue("") // Reset display if input is invalid
      onChange(null)
      setValue(editedKey, false, { shouldDirty: false })
    }
  }

  const handleFocus = () => {
    // Remove " %" when focusing the input for editing
    setDisplayValue(prev => prev.replace(" %", ""))
  }

  return (
    <>
      <CustomInput
        ref={ref}
        type="text"
        value={displayValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={event => {
          if (
            !specialKeys.includes(event.key) &&
            !allowedCharacters.test(event.currentTarget.value + event.key) &&
            !allowedCharacters.test(event.key)
          ) {
            event.preventDefault()
          }
          // Handle Enter key to focus on the next input
          if (event.key === "Enter") {
            event.preventDefault()
            onEnter?.(index) // Call onEnter if it exists
          }
        }}
        index={index}
        isEdited={isEdited}
        isAdjusted={isAdjusted}
        textAlign={textAlign}
      />
      {fieldState.error && <ErrorIconWithTooltip errorMessage={fieldState.error.message} />}
    </>
  )
}
