import { useQuery } from "@tanstack/react-query"
import { useApi } from "../../../../hooks/useApi"
import { IAccountBookPairs } from "../../types"

const useRiskExposuresQuery = ({
  accountBookPairs,
  type,
}: {
  accountBookPairs: IAccountBookPairs
  type: string | null
}) => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(
    ["riskExposures", { accountBookPairs, type }],
    () => scientApi.trading.getRiskExposures({ accountBookPairs, type: type as string }),
    {
      staleTime: Infinity,
      enabled: !!type,
    },
  )

  return {
    riskExposures: data,
    isLoading,
    error,
  }
}

export default useRiskExposuresQuery
