import { useQuery } from "@tanstack/react-query"
import { useApi } from "./useApi"

const useCompanies = () => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(["companies"], scientApi.world.getCompanies, {
    retry: 1,
    staleTime: Infinity, // Static list, no need to refresh.
  })

  return {
    companies: data,
    error,
    isLoading,
  }
}

export default useCompanies
