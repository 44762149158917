import styled from "@emotion/styled"
import { FlexContainer, FlexContainerAndItem, FlexItem } from "../../../../styled/flexbox.styled"

export const FactorModelTablesContainer = styled(FlexContainer)({
  height: "100%",
  width: "100%",
  overflow: "auto",
  padding: "2px",
})

export const InformationTablesContainer = styled(FlexContainerAndItem)({
  height: "180px",
  width: "100%",
  overflow: "auto",
  padding: "2px",
})

export const FactorReturnsContainer = styled(FlexItem)({
  height: "100%",
  width: "100%",
  padding: "2px",
})
