import Big from "big.js"

/**
 * Sums the values of a specified field in an array of objects,
 * Works with both value as number or string
 * It uses Big.js to avoid error when summing decimals
 *
 * Possibility to define a second field, and to use it if the first field is null
 *
 * @returns {number} - The total sum of the specified field values
 */

export const sumFieldValues = ({
  rows,
  field1,
  field2,
}: {
  rows: ({ [key: string]: { value: Big | string | number | null } } | string)[]
  field1: string
  field2?: string
}): number => {
  const sum = rows.reduce((acc, row) => {
    if (row && typeof row === "object") {
      let value: Big | null = null
      if (field2) {
        const fieldValue = row[field1].value ?? row[field2].value
        if (
          typeof fieldValue === "number" ||
          (typeof fieldValue === "string" && !isNaN(Number(fieldValue)))
        ) {
          value = Big(fieldValue || 0)
        }
      } else {
        const fieldValue = row[field1].value
        if (
          typeof fieldValue === "number" ||
          (typeof fieldValue === "string" && !isNaN(Number(fieldValue)))
        ) {
          value = Big(fieldValue || 0)
        }
      }
      if (value !== null) {
        return acc.plus(value)
      }
    }
    return acc
  }, Big(0))

  return sum.toNumber()
}
