import { Tab, Tabs } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"

import { capitalizeFirstLetter } from "../../../utils"
import Portfolio from "./Portfolio/Portfolio"
import { PortfolioManagementContainer } from "./PortfolioManagement.styled"
import useRebalancingQuery from "./Rebalancing/hooks/useRebalancingQuery"
import Rebalancing from "./Rebalancing/Rebalancing"
import { RebalancingWithTargets } from "./Rebalancing/types"
import Risks from "./Risks/Risks"
import { RiskType } from "./Risks/types"
import Trading from "./Trading/Trading"
import { IAccountBookPairs, PortfolioDisplayTab } from "./types"

const PortfolioManagement = () => {
  const [selectedTab, setSelectedTab] = useState(PortfolioDisplayTab.POSITIONS)
  // Selected accounts/books
  const [selectedFilters, setSelectedFilters] = useState<IAccountBookPairs>([])
  // Select sector for Rebalancing
  const [selectedSector, setSelectedSector] = useState(RiskType.SECTOR_LEVEL_1)
  //Get rebalancing based on selectedFilters
  const { rebalancing, isLoading } = useRebalancingQuery({
    accountBookPairs: selectedFilters,
    type: selectedSector,
  })

  // Initialize React Hook Form here allow to acces state and method of the form in all tabs.
  const methods = useForm<RebalancingWithTargets>()
  const { reset: resetForm } = methods

  // Load rebalancing in the form state
  useEffect(() => {
    if (rebalancing && !isLoading) {
      resetForm(rebalancing) // Reset form when new rebalancing data
    }
  }, [rebalancing, resetForm, isLoading])

  return (
    <FormProvider {...methods}>
      <PortfolioManagementContainer flexDirection="column" selectedTab={selectedTab}>
        <Tabs
          id="pm-tabs"
          onChange={id => setSelectedTab(id as PortfolioDisplayTab)}
          selectedTabId={selectedTab}
          /**
           * renderActiveTabPanelOnly avoids warning:
           *      " AG Grid tried to call sizeColumnsToFit() but the grid is coming back
           *      with zero width, maybe the grid is not visible yet on the screen? "
           */
          renderActiveTabPanelOnly
          large
          animate={false} // prevents the indicator of the selected tab from being misplaced
        >
          <Tab
            id={PortfolioDisplayTab.POSITIONS}
            title={capitalizeFirstLetter(PortfolioDisplayTab.POSITIONS)}
            panel={
              <Portfolio
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                displayTab={PortfolioDisplayTab.POSITIONS}
              />
            }
          />
          <Tab
            id={PortfolioDisplayTab.ORDERS}
            title={capitalizeFirstLetter(PortfolioDisplayTab.ORDERS)}
            panel={
              <Portfolio
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                displayTab={PortfolioDisplayTab.ORDERS}
              />
            }
          />
          <Tab
            id={PortfolioDisplayTab.RISKS}
            title={capitalizeFirstLetter(PortfolioDisplayTab.RISKS)}
            panel={<Risks selectedFilters={selectedFilters} />}
          />
          <Tab
            id={PortfolioDisplayTab.TRADING}
            title={capitalizeFirstLetter(PortfolioDisplayTab.TRADING)}
            panel={<Trading />}
          />
          <Tab
            id={PortfolioDisplayTab.REBALANCING}
            title={capitalizeFirstLetter(PortfolioDisplayTab.REBALANCING)}
            panel={
              <Rebalancing
                selectedFilters={selectedFilters}
                selectedSector={selectedSector}
                setSelectedSector={setSelectedSector}
                isLoading={isLoading}
              />
            }
          />
        </Tabs>
      </PortfolioManagementContainer>
    </FormProvider>
  )
}

export default PortfolioManagement
