/** @jsxImportSource @emotion/react */
import { Validate } from "react-hook-form"

import { FlexContainerFullHeight } from "./Components.styled"
import { ErrorIconWithTooltip } from "./ErrorIconWithTooltip"
import { IBaseInputProps } from "../types/common"
import { WaveInputs } from "../types/form"

export interface IFloatInputProps<TFieldValues> extends IBaseInputProps {
  validate?: Validate<string, TFieldValues> | Record<string, Validate<string, TFieldValues>>
}

/**
 * Component to use with React Hook Form for string decimal input.
 */

export const StringDecimalInput = ({
  name,
  register,
  customCss,
  error,
  validate,
}: IFloatInputProps<WaveInputs>) => {
  return (
    <FlexContainerFullHeight alignItems="center" justifyContent="center" alignContent="center">
      <input
        type="text"
        {...register(name, {
          validate: validate,
          // Transform the value before storing it in the form state
          setValueAs: (value: string) => {
            // If value is an empty string, return undefined
            return value === "" ? undefined : value
          },
        })}
        // Allows only numeric characters
        onKeyDown={event => {
          const allowedCharacters =
            /[0-9]|\.|Tab|Backspace|Delete|ArrowLeft|ArrowRight|ArrowDown|ArrowUp/
          if (!allowedCharacters.test(event.key)) {
            event.preventDefault()
          }
        }}
        css={customCss}
      />
      {error && <ErrorIconWithTooltip errorMessage={error.message} />}
    </FlexContainerFullHeight>
  )
}
