import { IconProps as BPIconProps, Icon } from "@blueprintjs/core"
import CyclicalyIcon from "../../../../icons/CyclicalyIcon"
import FactoryIcon from "../../../../icons/FactoryIcon"
import StyleIcon from "../../../../icons/StyleIcon"
import { RiskType } from "./types"

// Defines custom icon props within risk
interface CustomIconProps {
  margin?: string
  size?: number
  width?: string
  height?: string
  color?: string
}

// Defines the props for the risk icon, either custom or blueprint
type RiskIconProps = CustomIconProps | BPIconProps

// Defines the risk display information relative to the risk type
interface RiskInfo {
  iconComponent: React.ComponentType<RiskIconProps> // Icon component to be rendered
  label: string
  iconProps?: RiskIconProps // Props to be passed to the icon component
}

// Defines the risk display information for each risk type
export const RiskTypeInfo: Record<RiskType, RiskInfo> = {
  [RiskType.SECTOR_LEVEL_1]: {
    iconComponent: FactoryIcon,
    iconProps: {
      margin: "0 0 6px",
    },
    label: "Sector Level 1",
  },
  [RiskType.SECTOR_LEVEL_2]: {
    iconComponent: FactoryIcon,
    iconProps: {
      margin: "0 0 6px",
    },
    label: "Sector Level 2",
  },
  [RiskType.SECTOR_LEVEL_3]: {
    iconComponent: FactoryIcon,
    iconProps: {
      margin: "0 0 6px",
    },
    label: "Sector Level 3",
  },
  [RiskType.CYCLICALITY]: {
    iconComponent: CyclicalyIcon,
    label: "Cyclicality",
  },
  [RiskType.CURRENCIES]: {
    iconComponent: Icon,
    iconProps: {
      icon: "dollar",
      size: 28,
    },
    label: "Currencies",
  },
  [RiskType.GEO]: {
    iconComponent: Icon,
    iconProps: {
      icon: "globe",
      size: 28,
    },
    label: "Geo",
  },
  [RiskType.STYLES]: {
    iconComponent: StyleIcon,
    label: "Factors",
  },
}
