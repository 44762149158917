import { H3 } from "@blueprintjs/core"
import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { LargeText } from "../../../../styled/text.styled"

export const MainContainer = styled(FlexContainer)({
  height: "100%",
})

export const HeaderContainer = styled(FlexContainer)({
  width: "100%",
})

export const TickerListContainer = styled(FlexContainer)({
  width: "80%",
})

export const SummaryContainer = styled(FlexContainer)({
  height: "100%",
  width: "80%",
  overflow: "auto",
  backgroundColor: SCIENT_COLORS.darkWhite,
  borderRadius: "2px",
})

export const WaveTitle = styled(H3)({
  margin: 0,
})

export const TickersList = styled(LargeText)({
  marginTop: "2px",
})

export const NoWaveDetailPlaceholder = styled(FlexContainer)({
  height: "100%",
})
