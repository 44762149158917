import { Button } from "@blueprintjs/core"
import styled from "@emotion/styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"

export const ResetButton = styled(Button)({
  backgroundColor: `${SCIENT_COLORS.darkGray5}!important`,
})

export const AdjustButton = styled(Button)({
  backgroundColor: `${SCIENT_COLORS.gold3} !important`,
})
