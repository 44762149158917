/**
 * Generates a regular expression for validating numeric input based on specified criteria.
 *
 * Note: This funct can be used to validate numeric string inputs.
 *
 * @param {boolean} allowNegativeValues - Indicates whether negative values are allowed.
 * @param {boolean} onlyNegativeValues - Indicates whether only negative values are allowed.
 * @param {number} decimal - Specifies whether decimal values are allowed (greater than 0 for decimals).
 *
 * @returns {RegExp} A regular expression that matches valid numeric input according to the provided parameters.
 *
 * The function behaves as follows:
 * - If `onlyNegativeValues` is true:
 *   - If `decimal` is greater than 0, it allows negative decimals (e.g., -1.5).
 *   - If `decimal` is 0, it allows only negative integers (e.g., -1).
 * - If `onlyNegativeValues` is false:
 *   - If `allowNegativeValues` is true and `decimal` is greater than 0, it allows both positive and negative decimals (e.g., -1.5, 1.5).
 *   - If `allowNegativeValues` is true and `decimal` is 0, it allows both positive and negative integers (e.g., -1, 1).
 *   - If `allowNegativeValues` is false and `decimal` is greater than 0, it allows only positive decimals (e.g., 1.5).
 *   - If `allowNegativeValues` is false and `decimal` is 0, it allows only positive integers (e.g., 1).
 */

export const getNumberValidationRegex = (
  allowNegativeValues: boolean,
  onlyNegativeValues: boolean,
  decimal: number,
) => {
  if (onlyNegativeValues) {
    if (decimal > 0) {
      // Allows negative decimals
      return /^-\d*\.?\d*$/
    } else {
      // Negative integers only
      return /^-\d*$/
    }
  } else {
    if (allowNegativeValues && decimal > 0) {
      // Allows positive and negative decimals
      return /^-?\d*\.?\d*$/
    } else if (allowNegativeValues && decimal === 0) {
      // Positive and negative integers only
      return /^-?\d*$/
    } else if (!allowNegativeValues && decimal > 0) {
      // Allows positive decimals only
      return /^\d*\.?\d*$/
    } else {
      // Positive integers only
      return /^\d*$/
    }
  }
}
