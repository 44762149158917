/**
 * Function to lighten or darken a given hex color by adjusting its red, green, and blue (RGB) values.
 * It works by modifying each color channel (red, green, blue) based on a given adjustment value (amt).
 * Positive values for amt lighten the color, while negative values darken it.
 */

export const lightenDarkenColor = (col: string, amt: number): string => {
  let usePound = false

  // Remove the hash symbol if present
  if (col[0] === "#") {
    col = col.slice(1)
    usePound = true
  }

  // Parse the color string and extract RGB values
  const num = parseInt(col, 16)

  let r = (num >> 16) + amt
  let g = ((num >> 8) & 0x00ff) + amt
  let b = (num & 0x0000ff) + amt

  // Clamp RGB values between 0 and 255
  r = Math.max(0, Math.min(255, r))
  g = Math.max(0, Math.min(255, g))
  b = Math.max(0, Math.min(255, b))

  // Convert RGB values back to a hex string with proper zero-padding
  const newColor = ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")

  // Return the hex color with the pound symbol if required
  return (usePound ? "#" : "") + newColor
}
