import { Intent } from "@blueprintjs/core"
import { useState } from "react"

import SearchStock from "../components/SearchStock"
import AddNewTickerDialog from "./AddNewTickerDialog"
import {
  AddTickerButton,
  SearchStockContainer,
  StockContainer,
  StockDetailContainer,
} from "./Stock.styled"
import StockForm from "./StockForm"
import StockListings from "./StockListings"
import { TradingStock } from "./types"

const Stock = () => {
  const [selectedStock, setSelectedStock] = useState<TradingStock | undefined>(undefined)
  const [isOpenDialog, setIsOpenDialog] = useState(false)

  return (
    <StockContainer flexDirection="column" alignItems="center" justifyContent="center" gap="30px">
      <SearchStockContainer flexDirection="column" gap="20px">
        <AddTickerButton onClick={() => setIsOpenDialog(true)} icon="plus" intent={Intent.PRIMARY}>
          Add new ticker
        </AddTickerButton>
        <AddNewTickerDialog isOpenDialog={isOpenDialog} setIsOpenDialog={setIsOpenDialog} />
        <SearchStock setSelectedStock={setSelectedStock} />
      </SearchStockContainer>

      {selectedStock && (
        <StockDetailContainer>
          <StockForm selectedStock={selectedStock} setSelectedStock={setSelectedStock} />
          <StockListings listings={selectedStock.listings} />
        </StockDetailContainer>
      )}
    </StockContainer>
  )
}

export default Stock
