/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react"
import { UseControllerProps, useController } from "react-hook-form"

import { getNumberValidationRegex } from "../../utils/getNumberValidationRegex"
import { inputCss } from "../OrdersForm.styled"
import { WaveInputs } from "../types/form"
import { FlexContainerFullHeight } from "./Components.styled"
import { ErrorIconWithTooltip } from "./ErrorIconWithTooltip"

/**
 * Specific Input for use in Flash Trading form, where suffix '%' is add onBlur.
 * It takes value, format to 1 decimal place, add suffix " %" and display it.
 * onChange, value is stored as string
 */

interface IPercentageInputProps extends UseControllerProps<WaveInputs> {
  index: number
  decimal?: number
  allowNegativeValues?: boolean
  onlyNegativeValues?: boolean
}

export const PercentageInput = ({
  index,
  decimal = 0,
  control,
  name,
  rules,
}: IPercentageInputProps) => {
  const {
    field: { ref, value, onChange },
    fieldState,
  } = useController({ control, name, rules })

  const [displayValue, setDisplayValue] = useState<string>("")

  // Sync display value with form value
  useEffect(() => {
    if (!value) {
      setDisplayValue("")
    } else {
      setDisplayValue(`${(parseFloat(value) * 100).toFixed(decimal)} %`)
    }
  }, [value, decimal])

  // Restrict characters to number
  const allowedCharacters = getNumberValidationRegex(
    false, // not allow negative value
    false, // not allow only negative value
    decimal,
  )

  // define the allowed special keys
  const specialKeys = ["Tab", "Backspace", "Delete", "ArrowLeft", "ArrowRight", "ArrowUp"]

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (allowedCharacters.test(inputValue)) {
      setDisplayValue(inputValue)
    }
  }

  // Format on blur with one decimal place and '%' suffix and store value in form state
  const handleBlur = () => {
    const numericValue = parseFloat(displayValue)
    if (!isNaN(numericValue)) {
      // Format to decimal according prop and add '%' suffix
      const formattedValue = numericValue.toFixed(decimal)
      setDisplayValue(`${formattedValue} %`)
      // Store percentage as decimal in form
      onChange((numericValue / 100).toFixed(decimal + 2)) // decimal + 2 because we divide by 100
    } else {
      setDisplayValue("") // Reset display if input is invalid
      onChange(null)
    }
  }

  const handleFocus = () => {
    // Remove " %" when focusing the input for editing
    setDisplayValue(prev => prev.replace(" %", ""))
  }

  return (
    <FlexContainerFullHeight alignItems="center" justifyContent="center" alignContent="center">
      <input
        ref={ref}
        type="text"
        value={displayValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={event => {
          if (
            !specialKeys.includes(event.key) &&
            !allowedCharacters.test(event.currentTarget.value + event.key) &&
            !allowedCharacters.test(event.key)
          ) {
            event.preventDefault()
          }
        }}
        css={inputCss(index)}
      />
      {fieldState.error && <ErrorIconWithTooltip errorMessage={fieldState.error.message} />}
    </FlexContainerFullHeight>
  )
}
