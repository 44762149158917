import { Icon } from "@blueprintjs/core"
import { Dispatch, SetStateAction, useCallback } from "react"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { LargeText, MediumText } from "../../../../styled/text.styled"
import { capitalizeFirstLetter } from "../../../../utils"
import { transformNumberToStringWithSuffix } from "../utils/transformNumber"
import { waveStatusColor } from "../utils/waveStatusColor"
import {
  ContentContainer,
  LeftContainer,
  RightContainer,
  WaveCardContainer,
} from "./WaveCard.styled"
import WaveTickerList from "./WaveTickerList"
import { Wave } from "./types/wave"

interface IWaveCard {
  wave: Wave
  selected: boolean
  setSelectedWaveId: Dispatch<SetStateAction<string | number | undefined>>
}

const WaveCard = ({ wave, selected, setSelectedWaveId }: IWaveCard) => {
  const is_gmv_available = wave?.orders.every(order => order.amount_usd !== null)
  const gmv_value = wave?.orders.reduce((acc, order) => {
    return acc + (order.amount_usd ?? 0)
  }, 0)

  /**
   * When WaveCard is clicked, we set it selected
   */
  const onClickCard = useCallback(() => {
    setSelectedWaveId(wave.id)
  }, [setSelectedWaveId, wave.id])

  return (
    <WaveCardContainer selected={selected} onClick={onClickCard} gap="20px">
      <FlexContainer alignItems="flex-end">
        <Icon icon="full-circle" size={20} color={waveStatusColor(wave.status)} />
      </FlexContainer>

      <ContentContainer
        flexDirection="row-reverse"
        justifyContent="space-between"
        alignItems="center"
        gap="20px"
      >
        <RightContainer flexDirection="column" gap="8px">
          {is_gmv_available && (
            <>
              <MediumText color="#ABB3BF">GMV</MediumText>
              <MediumText>{transformNumberToStringWithSuffix(gmv_value)}</MediumText>
            </>
          )}
        </RightContainer>
        <WaveTickerList orders={wave.orders} />
        <LeftContainer flexDirection="column" gap="8px">
          <LargeText fontWeight="bold">{`Wave ${wave.id}`}</LargeText>
          <MediumText>{capitalizeFirstLetter(wave.status)}</MediumText>
        </LeftContainer>
      </ContentContainer>
    </WaveCardContainer>
  )
}

export default WaveCard
