/** @jsxImportSource @emotion/react */

import { Button, Intent, Spinner, SpinnerSize } from "@blueprintjs/core"
import { useQueryClient } from "@tanstack/react-query"
import { useEffect, useState } from "react"

import { FlexContainer } from "../../../styled/flexbox.styled"
import { LargeText, MediumItalicText } from "../../../styled/text.styled"
import { OTPMachineState } from "../../AuthProvider"
import { ScientLogoNew } from "../../components/ScientLogo/ScientLogoNew"
import { useAuth } from "../../hooks"
import { useApi } from "../../hooks/useApi"
import { useVerifyOTPMutation } from "../../hooks/useOtpMutation"
import { toaster } from "../../utils/createToaster"
import { loginSubmitButtonCss, TransitionContainer } from "./Login.styled"
import TokenInput from "./TokenInput"

const VerifyOTP = () => {
  const { setOtpMachineState, logout } = useAuth()
  const { setApi2FAVerified } = useApi()
  const queryClient = useQueryClient()

  const [tokenParts, setTokenParts] = useState(Array(6).fill(""))

  const { mutate: mutateVerifyOtp, isLoading: isLoadingVerify } = useVerifyOTPMutation({
    onSuccess: () => {
      setOtpMachineState && setOtpMachineState(OTPMachineState.OK)
      setApi2FAVerified && setApi2FAVerified(true)
      queryClient.invalidateQueries(["profile"])
    },
    onError: () => {
      toaster.show({
        message: "Invalid token, please retry.",
        intent: "danger",
      })
    },
  })

  const onSubmit = () => {
    mutateVerifyOtp(tokenParts.join(""))
  }

  // Submit if 6 digits are entered
  useEffect(() => {
    if (tokenParts.join("").length === 6) {
      mutateVerifyOtp(tokenParts.join(""))
    }
  }, [mutateVerifyOtp, tokenParts])

  return (
    <TransitionContainer flexDirection="column" alignItems="center" gap="20px">
      <form onSubmit={onSubmit}>
        <FlexContainer flexDirection="column" gap="15px" alignItems="center">
          <ScientLogoNew height="60px" />
          <LargeText color="#2e58a5">2FA Verification</LargeText>
          <MediumItalicText color="#2e58a5">
            Open your Authenticator App and enter the token below
          </MediumItalicText>
          {isLoadingVerify ? (
            <Spinner size={SpinnerSize.LARGE} />
          ) : (
            <>
              <TokenInput tokenParts={tokenParts} setTokenParts={setTokenParts} />
              <Button
                fill
                intent="primary"
                rightIcon="log-in"
                type="submit"
                disabled={tokenParts.join("").length !== 6}
                css={loginSubmitButtonCss}
              >
                Verify Token
              </Button>
              <Button
                fill
                intent={Intent.NONE}
                css={loginSubmitButtonCss}
                onClick={() => logout && logout()}
              >
                Logout
              </Button>
            </>
          )}
        </FlexContainer>
      </form>
    </TransitionContainer>
  )
}

export default VerifyOTP
