import { Intent, OverlayToaster, Position, Toaster } from "@blueprintjs/core"
import Papa, { ParseResult } from "papaparse"
import { ChangeEvent, useEffect, useState } from "react"
import { UseFormSetValue } from "react-hook-form"

import { createToaster } from "../../../utils/createToaster"
import useBooksQuery from "../Portfolio/hooks/useBooksQuery"
import { ScientTradingAccount } from "../types"
import UploadButton from "./components/UploadButton"
import { ExecutionType, ITickerItem, OrderType, Side } from "./types/common"
import { CSVOrder, WaveInputs } from "./types/form"
import {
  checkColumnsName,
  validateCAP,
  validateExecutionType,
  validateFloat,
  validateInteger,
  validateOrderType,
  validateSide,
  validateTargetEndTime,
} from "./utils/csvValidationFunctions"
import { normalizeCSVData } from "./utils/normalizeCSVData"

// Create toaster instance
const toaster: Toaster = OverlayToaster.create({ position: Position.TOP })

interface ICSVUploaderProps {
  accounts: ScientTradingAccount[]
  resetWaveForm: () => void
  setValue: UseFormSetValue<WaveInputs>
}

const CSVUploader = ({ accounts, resetWaveForm, setValue }: ICSVUploaderProps) => {
  const [accountIdFromCSV, setAccountIdFromCSV] = useState<number | null>(null)
  const [csvData, setCsvData] = useState<CSVOrder[] | null>(null)
  const [errorMessage, setErrorMessage] = useState<string>("")

  // Getting all user's books related to selected account
  const {
    books,
    isLoading: isLoadingBooks,
    error: booksError,
  } = useBooksQuery(
    { accountIds: accountIdFromCSV !== null ? [accountIdFromCSV] : [] },
    { enabled: !!accountIdFromCSV }, // Enable the query only when accountIdFromCSV is not null
  )

  // Effect to load orders in form state
  useEffect(() => {
    if (!isLoadingBooks && csvData) {
      if (booksError) {
        setErrorMessage(
          "An error occurred while fetching your books. Please try again or contact us using intercom.",
        )
        return // Stop execution
      }
      const booksInCSV = csvData.filter(item => item.BOOK !== "").map(item => item.BOOK)

      if (booksInCSV.length > 0) {
        const validBooks = booksInCSV.every(book => books.some(b => b.code === book))
        if (!validBooks) {
          setErrorMessage(
            "CSV Error: One or more books in the CSV do not exist in the provided account.",
          )
          return // Stop execution
        }
      }
      const orders = csvData.map(row => {
        // Validate and parse fields
        const usd = row.USD ? validateInteger(row.USD, "USD", setErrorMessage) : undefined
        const qty = row.QTY ? validateInteger(row.QTY, "QTY", setErrorMessage) : undefined
        const timeInMarket = row["TIME IN MARKET"]
          ? validateInteger(row["TIME IN MARKET"], "TIME IN MARKET", setErrorMessage)
          : undefined
        const limit = row.LIMIT ? validateFloat(row.LIMIT, "LIMIT", setErrorMessage) : undefined
        const executionCapPct = row.CAP ? validateCAP(row.CAP, "CAP", setErrorMessage) : ""
        const executionType = row.EXECUTION
          ? validateExecutionType(row.EXECUTION, setErrorMessage)
          : ExecutionType.HERE
        const orderType = row.TYPE ? validateOrderType(row.TYPE, setErrorMessage) : OrderType.MARKET
        const side = row.SIDE ? validateSide(row.SIDE, setErrorMessage) : Side.BUY
        const targetEnd = row["TARGET END"]
          ? validateTargetEndTime(row["TARGET END"], setErrorMessage)
          : ""

        const bookId = books.find(bk => bk.code === row.BOOK)?.id

        return {
          amount_usd: usd,
          book_id: bookId,
          execution_cap_pct: executionCapPct,
          execution_details: row.COMMENT ? row.COMMENT : "",
          execution_type: executionType,
          limit_price: limit,
          order_type: orderType,
          quantity: qty,
          side: side,
          symbol: { ticker: row.TICKER ? row.TICKER : "" } as ITickerItem,
          target_end_datetime: targetEnd,
          time_in_market: timeInMarket,
        }
      })

      // Populate the React Hook Form state
      setValue("orders", orders)
    }
  }, [books, csvData, isLoadingBooks, setValue, booksError])

  // Effect to display error message in toaster
  useEffect(() => {
    if (errorMessage) {
      resetWaveForm() // reset wave if error
      toaster.show({
        message: errorMessage,
        intent: Intent.DANGER,
      })
      setErrorMessage("") // Reset the error message after showing the toast
      return
    }
  }, [errorMessage, resetWaveForm])

  const handleCsvFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      Papa.parse(file, {
        header: true,
        delimiter: "", // auto-detect
        skipEmptyLines: "greedy", // to skip also lines that contain only delimiter (no value)
        // callback to execute when parsing is completed. It receives the parse results.
        complete: (results: ParseResult<CSVOrder>) => {
          if (results.data.length === 0) {
            createToaster({
              message: "CSV Error: The CSV is empty.",
              intent: Intent.DANGER,
            })
            return // ensures that the function stops execution at this point
          }
          const normalizedData: CSVOrder[] = normalizeCSVData(results.data)

          // Check if there's invalid column name
          const invalidColName = checkColumnsName(normalizedData)

          if (invalidColName) {
            createToaster({
              message: `CSV Error: Wrong column name: ${invalidColName.join(
                ", ",
              )}. Click on info icon near the upload CSV button to get info about columns name.`,
              intent: Intent.DANGER,
            })
            return // Stop execution
          }

          if (normalizedData.length > 20) {
            createToaster({
              message: "CSV Error: The CSV contains more than 20 orders.",
              intent: Intent.DANGER,
            })
            return // Stop execution
          }

          // extract the list of Account's names from the CSV
          const accountsInCSV = normalizedData
            .filter(item => item.ACCOUNT !== "")
            .map(item => item.ACCOUNT)
          //extract the list of Book's names from the CSV
          const booksInCSV = normalizedData.filter(item => item.BOOK !== "").map(item => item.BOOK)

          // Check if all Accounts are the same
          const isAccountUniq = accountsInCSV.every(account => account === accountsInCSV[0])

          // Return an error message if there are different accounts in the CSV. Only one is allowed
          if (!isAccountUniq) {
            createToaster({
              message:
                "CSV Error: Different accounts found in the CSV. Account must be the same for all orders.",
              intent: Intent.DANGER,
            })
            return
          }
          // Find the corresponding account_id
          const accountId = accounts.find(account => account.name === accountsInCSV[0])?.id || null

          // If accountId=null but there is account in the CSV so the account name in CSV is not valid
          if (!accountId && accountsInCSV.length > 0) {
            createToaster({
              message: "CSV Error: No valid account found for the provided account name.",
              intent: Intent.DANGER,
            })
            return // Stop execution
          }

          // If accountId=null but there is book(s) in the CSV, return error.
          if (!accountId && booksInCSV.length > 0) {
            createToaster({
              message: "CSV Error: there is books in CSV but no account provided.",
              intent: Intent.DANGER,
            })
            return // Stop execution
          }

          // If we reach here, it means we can launch  more validation check then if validation passed load orders in form state
          if (accountId || (!accountId && booksInCSV.length === 0)) {
            resetWaveForm()
            accountId && setAccountIdFromCSV(accountId) // This will trigger useBooksQuery to fetch books
            accountId && setValue("account_id", accountId)
            setCsvData(normalizedData)
          }
        },
        error: () => {
          createToaster({
            message: "Error parsing the CSV file. Please verify CSV or us using intercom.",
            intent: Intent.DANGER,
          })
        },
      })
      // Reset the file input value to allow showing again toast error
      // when user try many times to upload the same files with error
      event.target.value = ""
    }
  }

  return <UploadButton onChange={handleCsvFile} fill />
}

export default CSVUploader
