import { AxiosInstance } from "axios"

import {
  Country,
  Currency,
  StockUpdatePayload,
  TradingSector,
  TradingStock,
} from "../views/pages/Config/Stock/types"
import {
  IRefreshTradingDataResponse,
  ITradingData,
  ITradingSummary,
} from "../views/pages/PortfolioManagement/Portfolio/types"
import {
  RebalancingData,
  RebalancingResult,
} from "../views/pages/PortfolioManagement/Rebalancing/types"
import {
  IFactorModels,
  IRiskExposures,
  IRiskKeyMetrics,
  RiskZoom,
} from "../views/pages/PortfolioManagement/Risks/types"
import { ComplianceRules } from "../views/pages/PortfolioManagement/Trading/types/compliance"
import { Wave } from "../views/pages/PortfolioManagement/Trading/types/wave"
import { ScientTradingAccount, TradingBook } from "../views/pages/PortfolioManagement/types"

const WAVES_PER_PAGE = 15

export interface ITradingAPI {
  getBooks: ({ accountIds }: { accountIds?: number[] }) => Promise<TradingBook[]>
  getTradingAccountsScient: () => Promise<ScientTradingAccount[]>
  getTradingSummary: ({ liveOrders }: { liveOrders: boolean }) => Promise<ITradingSummary>
  getTradingData: ({
    accountBookPairs,
    liveOrders,
  }: {
    accountBookPairs?: Array<{ account_id: number; book_ids?: number[] }>
    liveOrders: boolean
  }) => Promise<ITradingData>
  refreshTradingData: () => Promise<IRefreshTradingDataResponse>
  getRiskKeyMetrics: ({
    accountBookPairs,
  }: {
    accountBookPairs?: Array<{ account_id: number; book_ids?: number[] }>
  }) => Promise<IRiskKeyMetrics>
  getRiskExposures: ({
    accountBookPairs,
    type,
  }: {
    accountBookPairs?: Array<{ account_id: number; book_ids?: number[] }>
    type: string
  }) => Promise<IRiskExposures>
  getRiskZoom: ({
    accountBookPairs,
    type,
    category,
  }: {
    type: string
    accountBookPairs?: Array<{ account_id: number; book_ids?: number[] }>
    category?: string
  }) => Promise<RiskZoom>
  getFactorModels: () => Promise<IFactorModels>
  getTradeWaves: (pageParam?: string) => Promise<{
    data: Wave[]
    next_cursor: string | null
  }>
  create: (values: Partial<Wave>) => Promise<Wave>
  getComplianceRules: () => Promise<ComplianceRules>
  getRebalancingExposures: ({
    accountBookPairs,
    type,
  }: {
    accountBookPairs?: Array<{ account_id: number; book_ids?: number[] }>
    type: string
  }) => Promise<RebalancingData>
  createRebalanceTrade: (values: RebalancingResult) => Promise<Blob>
  getTradingCurrencies: () => Promise<Currency[]>
  getTradingCountries: () => Promise<Country[]>
  getTradingSectors: () => Promise<TradingSector[]>
  getTradingStocks: ({ query }: { query: string }) => Promise<TradingStock[]>
  updateTradingStock: ({
    stockId,
    stock,
  }: {
    stockId: number
    stock: StockUpdatePayload
  }) => Promise<TradingStock>
}

const trading = (axiosClient: AxiosInstance): ITradingAPI => ({
  getBooks: async ({ accountIds }: { accountIds?: number[] }) => {
    const { data } = await axiosClient.get<TradingBook[]>("/trading/books", {
      params: { account_id: accountIds },
    })

    return data
  },
  getTradingAccountsScient: async () => {
    const { data } = await axiosClient.get<ScientTradingAccount[]>("/trading/accounts")

    return data
  },
  getTradingSummary: async ({ liveOrders }: { liveOrders: boolean }) => {
    const { data } = await axiosClient.get<ITradingSummary>("/trading/summary", {
      params: { live_orders: liveOrders },
    })

    return data
  },
  getTradingData: async ({
    accountBookPairs,
    liveOrders,
  }: {
    accountBookPairs: Array<{ account_id: number; book_ids?: number[] }>
    liveOrders: boolean
  }) => {
    const { data } = await axiosClient.get<ITradingData>("/trading", {
      params: {
        account_book_pairs: JSON.stringify(accountBookPairs),
        live_orders: liveOrders,
      },
    })

    return data
  },
  /**
   * POST /trading/refresh/
   */
  refreshTradingData: async () => {
    const { data } = await axiosClient.post<IRefreshTradingDataResponse>("/trading/refresh/")

    return data
  },
  getRiskKeyMetrics: async ({
    accountBookPairs,
  }: {
    accountBookPairs: Array<{ account_id: number; book_ids?: number[] }>
  }) => {
    const { data } = await axiosClient.get<IRiskKeyMetrics>("/trading/risk/key-metrics", {
      params: { account_book_pairs: JSON.stringify(accountBookPairs) },
    })

    return data
  },
  getRiskExposures: async ({
    accountBookPairs,
    type,
  }: {
    accountBookPairs: Array<{ account_id: number; book_ids?: number[] }>
    type: string
  }) => {
    const { data } = await axiosClient.get<IRiskExposures>("/trading/risk/exposures", {
      params: { account_book_pairs: JSON.stringify(accountBookPairs), type },
    })

    return data
  },
  getRiskZoom: async ({
    accountBookPairs,
    type,
    category,
  }: {
    accountBookPairs: Array<{ account_id: number; book_ids?: number[] }>
    type: string
    category: string
  }) => {
    const { data } = await axiosClient.get<RiskZoom>("/trading/risk/zoom", {
      params: { account_book_pairs: JSON.stringify(accountBookPairs), type, category },
    })

    return data
  },
  getFactorModels: async () => {
    const { data } = await axiosClient.get<IFactorModels>("/trading/factor-models/")

    return data
  },
  /**
   * GET /trading/waves/
   */
  getTradeWaves: async (pageParam: string) => {
    const { data } = await axiosClient.get<{ data: Wave[]; next_cursor: string }>(
      `/trading/waves/`,
      {
        params: {
          per_page: WAVES_PER_PAGE,
          next_cursor: pageParam,
        },
      },
    )

    return data
  },
  /**
   * POST /waves/
   */
  create: async (values: Partial<Wave>) => {
    const { data } = await axiosClient.post<Wave>("/trading/waves/", values)

    return data
  },

  /**
   * GET /trading/compliance/rules/
   */
  getComplianceRules: async () => {
    const { data } = await axiosClient.get<ComplianceRules>("/trading/compliance/rules/")

    return data
  },

  /**
   * GET /trading/rebalancing/
   */
  getRebalancingExposures: async ({
    accountBookPairs,
    type,
  }: {
    accountBookPairs: Array<{ account_id: number; book_ids?: number[] }>
    type: string
  }) => {
    const { data } = await axiosClient.get<RebalancingData>("/trading/rebalancing/", {
      params: { account_book_pairs: JSON.stringify(accountBookPairs), type },
    })

    return data
  },

  /**
   * POST /trading/rebalance/
   */
  createRebalanceTrade: async (values: RebalancingResult) => {
    const { data } = await axiosClient.post<Blob>("/trading/rebalance/", values, {
      responseType: "blob",
    })

    return data
  },

  /**
   * GET /api/v1/trading/currencies/
   */
  getTradingCurrencies: async () => {
    const { data } = await axiosClient.get<Currency[]>("trading/currencies/")

    return data
  },

  /**
   * GET /api/v1/trading/countries/
   */
  getTradingCountries: async () => {
    const { data } = await axiosClient.get<Country[]>("trading/countries/")

    return data
  },

  /**
   * GET /api/v1/trading/sectors/
   */
  getTradingSectors: async () => {
    const { data } = await axiosClient.get<TradingSector[]>("trading/sectors/")

    return data
  },

  /**
   * GET /api/v1/trading/stocks/
   */
  getTradingStocks: async ({ query }: { query: string }) => {
    const { data } = await axiosClient.get<TradingStock[]>("trading/stocks/", {
      params: { ticker: query },
    })

    return data
  },

  /**
   * PUT /api/v1/trading/stocks/${stockId}/
   */
  updateTradingStock: async ({
    stockId,
    stock,
  }: {
    stockId: number
    stock: StockUpdatePayload
  }) => {
    const { data } = await axiosClient.put<TradingStock>(`trading/stocks/${stockId}/`, stock)

    return data
  },
})

export default trading
