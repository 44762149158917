import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"

export const TradingWavePageContainer = styled(FlexContainer)({
  gridArea: "content",
  height: "100%",
  width: "100%",
  overflow: "auto",
  padding: "10px 15px",
})
