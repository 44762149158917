import { useQuery } from "@tanstack/react-query"
import { useApi } from "../../../../hooks/useApi"

const useFactorModelsQuery = () => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(
    ["factorModels"],
    () => scientApi.trading.getFactorModels(),
    {
      staleTime: Infinity,
    },
  )

  return {
    factorModels: data,
    isLoading,
    error,
  }
}

export default useFactorModelsQuery
