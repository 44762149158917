import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { useApi } from "../../hooks/useApi"

const useBloombergData = ({ query }: { query: string }) => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(
    ["bloomberg_data", { query: query }],
    () => scientApi.bloomberg.getBloombergData({ query: query }),
    {
      retry: 1,
      enabled: query.length > 2, // Only fetch when query length is > 2
    },
  )

  return {
    bloombergData: data,
    error: error as AxiosError,
    isLoading,
  }
}

export default useBloombergData
