import {
  PositionRowField,
  PositionRowWithTargets,
  PositionsResults,
  RebalancingCategoryWithTargets,
  RebalancingResult,
  RebalancingWithTargets,
} from "../types"

/**
 * convert form data to a simple payload to be sent to the backend
 */
export const convertToRebalancingResult = (data: RebalancingWithTargets): RebalancingResult => {
  const results = data.categories.map(filterCategories).filter(row => Object.keys(row).length !== 0)
  const positions = results.flat()
  return { positions }
}

const filterCategories = ({ positions }: RebalancingCategoryWithTargets): PositionsResults[] => {
  const long_pos = positions.long.rows
    .filter(row => row !== "" && row[PositionRowField.TARGET_DOLLAR].value !== null)
    .map(filterPositionResults)
  const short_pos = positions.short.rows
    .filter(row => row !== "" && row[PositionRowField.TARGET_DOLLAR].value !== null)
    .map(filterPositionResults)
  return long_pos.concat(short_pos)
}

const filterPositionResults = ({ BBSymbol, ...rest }: PositionRowWithTargets): PositionsResults => {
  return {
    bb_symbol: BBSymbol.value,
    dollar_delta: rest[PositionRowField.DOLLAR_NMV].value,
    target_dollar_delta: rest[PositionRowField.TARGET_DOLLAR].value,
  }
}
