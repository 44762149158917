import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { LargeText } from "../../../../styled/text.styled"
import { ScientValueFormatter } from "../components/agGridMapping"
import { formatValue } from "../utils/formatValue"
import { MetricsContainer, SummaryContainer } from "./CategorySummary.styled"
import { RebalancingCategoryWithTargets } from "./types"

interface ICategorySummaryProps {
  category: RebalancingCategoryWithTargets
}

const CategorySummary = ({ category }: ICategorySummaryProps) => (
  <SummaryContainer justifyContent="space-between">
    <LargeText fontWeight="bold">{category?.name}</LargeText>
    <MetricsContainer gap="8%" justifyContent="flex-end">
      {Object.entries(category.summary).map(([key, value]) => (
        <LargeText fontWeight="bold" color={SCIENT_COLORS.green5} key={key}>
          {key.includes("%")
            ? formatValue({
                value,
                type: ScientValueFormatter.PERCENTAGE,
                withoutPercentSign: true,
              }) +
              " " +
              key
            : formatValue({ value, type: ScientValueFormatter.INT_GB }) + " " + key}
        </LargeText>
      ))}
    </MetricsContainer>
  </SummaryContainer>
)

export default CategorySummary
