import React, { useState } from "react"
import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { Button } from "./SectorButtons.styled"

interface ButtonGroupProps<T> {
  options: { label: string; value: T }[] // List of buttons with labels and values
  handleChange: (value: T) => void // Function to handle change event
  selected?: T // Selected value
}

export const ButtonGroup = <T,>({ options, handleChange, selected }: ButtonGroupProps<T>) => {
  return (
    <FlexContainer>
      {options.map((option, index) => (
        <Button
          key={option.label}
          onClick={() => handleChange(option.value)}
          withLeftBorderRadius={index === 0}
          withRightBorderRadius={index === options.length - 1}
          withBorders={index !== 0 && index !== options.length - 1}
          isActive={option.value === selected}
        >
          {option.label}
        </Button>
      ))}
    </FlexContainer>
  )
}
