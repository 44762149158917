import { Button, Spinner, SpinnerSize } from "@blueprintjs/core"
import { useCallback, useEffect, useState } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { FlexContainer } from "../../../styled/flexbox.styled"
import { LargeText, MediumItalicText } from "../../../styled/text.styled"
import { useAuth, useLocalStorage } from "../../hooks"
import { loginSubmitButtonCss, TransitionContainer } from "../Login/Login.styled"
import { BloombergAuthContainer } from "./BloomBergAuth.styled"
import { useCreateidentityMutation } from "./hooks/useCreateIdentity"
import { createToaster } from "../../utils/createToaster"

function base64urlencode(hashBuf: ArrayBuffer) {
  const array = new Uint8Array(hashBuf)
  const ascii = btoa(String.fromCharCode(...array))
  return ascii.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "")
}

function sha256(str: string) {
  const encoder = new TextEncoder()
  const data = encoder.encode(str)
  return window.crypto.subtle.digest("SHA-256", data)
}

const BloombergAuth = () => {
  const { hash } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [openToken, setToken] = useState<string | null>(null)
  const [codeVerifier, setCodeVerifier] = useLocalStorage("pkceCodeVerifier", undefined)

  const { profile } = useAuth()

  const { mutate: createIdentityMutate } = useCreateidentityMutation({
    onError: error => {
      createToaster({
        message: "Cannot create identity, please retry",
        intent: "danger",
      })
      setSearchParams(params => {
        params.delete("code")
        return params
      })
    },
    onSuccess: () => console.log("success"),
  })

  const redirectToBsso = useCallback(() => {
    window.location.href = "bbg://screens/BAPP%20scient_bbg_dev%20/"
  }, [])

  useEffect(() => {
    if (hash) {
      setToken(hash.split("=")[1])
    }
  }, [hash])

  const sendOpenToken = useCallback(async (openToken: string, code_verifier: string) => {
    const params = {
      ssotoken: openToken,
      adapter: "token",
      scope: "sapi blpapi-eps",
      response_type: "code",
      code_challenge: base64urlencode(await sha256(code_verifier)),
      code_challenge_method: "S256",
      redirect_uri: encodeURI("https://staging.scient.io/bbg"),
      client_id: "scient_bbg_dev",
    }

    const base = "https://bsso.blpprofessional.com/as/authorization.oauth2"
    const bssoUrl = base + "?" + new URLSearchParams(params).toString()

    window.location.replace(bssoUrl)
  }, [])

  useEffect(() => {
    if (openToken && codeVerifier) {
      sendOpenToken(openToken, codeVerifier)
    }
  }, [codeVerifier, openToken, sendOpenToken])

  useEffect(() => {
    if (openToken && !codeVerifier) {
      const code_verifier = generateRandomString(43)
      setCodeVerifier(code_verifier)
    }
  }, [sendOpenToken, openToken, setCodeVerifier, codeVerifier])

  useEffect(() => {
    const code = searchParams.get("code")
    if (code && codeVerifier) {
      createIdentityMutate({ code, code_verifier: codeVerifier })
      setCodeVerifier(undefined)
    }
  }, [codeVerifier, createIdentityMutate, searchParams, setCodeVerifier])

  const generateRandomString = (length: number) => {
    const array = new Uint32Array(length)
    window.crypto.getRandomValues(array)
    return Array.from(array, uint32 => ("0" + uint32.toString(16)).slice(-1)).join("")
  }

  return (
    <BloombergAuthContainer alignItems="center" justifyContent="center">
      <TransitionContainer flexDirection="column" gap="20px" alignItems="center">
        <FlexContainer flexDirection="column" gap="20px" alignItems="center">
          <LargeText color="#2e58a5" fontWeight="bold">
            Connect Bloomberg SAPI
          </LargeText>
          <img src={`${process.env.PUBLIC_URL}/bunit.png`} alt="bloomberg logo" height="100px" />
          {profile?.has_sapi_identity ? (
            <>
              <MediumItalicText color="#2e58a5">
                You are connected to SAPI. You can now access live market prices from Bloomberg.
              </MediumItalicText>
            </>
          ) : (
            <>
              {openToken && (
                <>
                  <MediumItalicText color="#2e58a5">
                    Exchanging data with Bloomberg. Please wait...
                  </MediumItalicText>
                  <Spinner size={SpinnerSize.SMALL} />
                </>
              )}
              {searchParams.get("code") && (
                <>
                  <MediumItalicText color="#2e58a5">Creating Identity...</MediumItalicText>
                  <Spinner size={SpinnerSize.SMALL} />
                </>
              )}
              {!openToken && !searchParams.get("code") && (
                <>
                  <MediumItalicText color="#2e58a5">
                    We need to connect you to Bloomberg BSSO in order for you to access live market
                    prices from Bloomberg in Scient Application.
                  </MediumItalicText>
                  <MediumItalicText color="#2e58a5">
                    On clicking the button below, you will be redirected to Bloomberg BSSO to
                    authorize the connection. Please make sure to have your bloomberg terminal open
                    and logged in.
                  </MediumItalicText>
                  <Button
                    fill
                    intent="primary"
                    rightIcon="log-in"
                    type="submit"
                    css={loginSubmitButtonCss}
                    onClick={() => redirectToBsso()}
                  >
                    Connect Bloomberg to Scient
                  </Button>
                </>
              )}
            </>
          )}
        </FlexContainer>
      </TransitionContainer>
    </BloombergAuthContainer>
  )
}

export default BloombergAuth
