import { css } from "@emotion/react"
import styled from "@emotion/styled"

import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { Side } from "./types/common"

export const TickerListText = styled.p({
  fontSize: "12px",
  margin: "2px 0 0 0",
})

type StyledTagProps = {
  isWaveDetail?: boolean
  side: Side
}

export const tagCss = ({ isWaveDetail, side }: StyledTagProps) => css`
 background-color: ${
   side === Side.BUY || side === Side.BUY_COVER
     ? `${SCIENT_COLORS.green3} !important`
     : `${SCIENT_COLORS.red3} !important`
 };
    width: ${
      isWaveDetail
        ? side === Side.BUY || side === Side.SELL
          ? "45px !important"
          : "82px !important"
        : side === Side.BUY || side === Side.SELL
          ? "35px !important"
          : "65px !important"
    };
  }
  &.bp5-tag {
    display: inline-table;
`
