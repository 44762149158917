import { FlexContainer, FlexItem } from "../../../../styled/flexbox.styled"
import Grid from "../components/Grid"
import {
  FactorModelTablesContainer,
  FactorReturnsContainer,
  InformationTablesContainer,
} from "./FactorModelTables.styled"

import { FactorModel, FactorModelTimeZones, IFactorModels } from "./types"

interface IFactorModelsProps {
  factorModels?: FactorModel[]
  selectedFactorModelTimezone: FactorModelTimeZones
}

const FactorModelTables = ({ selectedFactorModelTimezone, factorModels }: IFactorModelsProps) => {
  // Find the corresponding factor model based on selectedFactorModelTimezone
  const factorModel = factorModels?.find(
    (model: FactorModel) => model.timezone === selectedFactorModelTimezone,
  )

  const formatExplenationDate = (date: string) => {
    const dateObj = new Date(date)
    if (isNaN(dateObj.getTime())) return "-"
    return new Intl.DateTimeFormat("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(dateObj)
  }

  const explenationDate = factorModel?.factor_returns.last_updated_at
    ? formatExplenationDate(factorModel?.factor_returns.last_updated_at)
    : "-"

  return (
    <FactorModelTablesContainer flexDirection="column" gap="5px">
      {factorModel ? (
        <>
          <InformationTablesContainer flexDirection="row" gap="5px">
            <FlexItem flexGrow={1}>
              <Grid
                label={`Most Relevant Factors (${explenationDate})`}
                rows={factorModel?.best_factors.rows}
                columns={factorModel?.best_factors.columns}
              />
            </FlexItem>
            <FlexItem flexBasis={"400px"}>
              <Grid
                label="Goodness of Fit"
                rows={factorModel?.goodness_of_fit.rows}
                columns={factorModel?.goodness_of_fit.columns}
              />
            </FlexItem>
          </InformationTablesContainer>
          <FactorReturnsContainer flexGrow={1}>
            <Grid
              label={`Factor Returns (${explenationDate})`}
              rows={factorModel?.factor_returns.rows}
              columns={factorModel?.factor_returns.columns}
            />
          </FactorReturnsContainer>
        </>
      ) : (
        <FlexContainer justifyContent="center">
          <p>No factor model found for the selected time zone.</p>
        </FlexContainer>
      )}
    </FactorModelTablesContainer>
  )
}

export default FactorModelTables
