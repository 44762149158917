import { Button, Card, Dialog } from "@blueprintjs/core"
import styled from "@emotion/styled"

import { FlexContainer } from "../../../../styled/flexbox.styled"

export const StockContainer = styled(FlexContainer)({
  height: "fit-content",
  maxHeight: "100%",
  width: "100%",
  overflow: "hidden",
  padding: "10px",
})

export const SearchStockContainer = styled(FlexContainer)({
  width: "600px",
  marginTop: "10px",
})

export const StockDetailContainer = styled(Card)({
  padding: "10px 40px 20px",
  overflow: "hidden",
  flex: 1,
  display: "flex",
  flexDirection: "column",
})

export const ListingDialog = styled(Dialog)({
  maxHeight: "70vh",
  width: "600px",
})

export const AddTickerButton = styled(Button)({
  width: "100%",
})
