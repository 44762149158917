import { RiskType } from "../Risks/types"
import { ScientCellStyle, ScientRowStyle, ScientValueFormatter } from "../components/agGridMapping"

export enum KeyMetricsField {
  HEADER = "header",
  GMV = "GMV",
  NMV = "NMV",
  VAR = "VaR",
  NET_EXPOSURE = "Net Exposure",
}

export enum PositionRowField {
  BB_SYMBOL = "BBSymbol",
  DOLLAR_NMV = "$ NMV",
  INCREMENTAL_DOLLAR = "Incremental $",
  TARGET_DOLLAR = "Target $",
  PERCENT_GMV = "% GMV",
  TARGET_PERCENT_GMV = "Target % GMV",
  STM = "STM",
}
export enum SummaryField {
  NMV = "NMV",
  PERCENT_GMV = "% GMV",
}

/** Define all the available rebalancing sectors levels */
export type RebalancingType = RiskType.SECTOR_LEVEL_1 | RiskType.SECTOR_LEVEL_2

/** Define rebalancing data structure at import */
export type PositionColumn = {
  field: PositionRowField
  valueFormatter: ScientValueFormatter
}

export type PositionRow = {
  [PositionRowField.BB_SYMBOL]: string
  [PositionRowField.DOLLAR_NMV]: number
  [PositionRowField.PERCENT_GMV]: number
  [PositionRowField.STM]: number | null
}

export type RowStyle = {
  rowIndex: number
  rowStyle: ScientRowStyle
}

export type PinnedBottomRows = {
  rowStyles: RowStyle[]
  rowData: PositionRow[]
}

export type PositionData = {
  columns: PositionColumn[]
  rows: PositionRow[]
  pinnedBottomRows: PinnedBottomRows
  last_updated_at: string | null
}

type Positions = {
  long: PositionData
  short: PositionData
}

type Summary = {
  [SummaryField.PERCENT_GMV]: number
  [SummaryField.NMV]: number
}

export type RebalancingCategory = {
  name: string
  positions: Positions
  summary: Summary
}

export type KeyMetricsRow = {
  [KeyMetricsField.GMV]: number
  [KeyMetricsField.NMV]: number
  [KeyMetricsField.NET_EXPOSURE]: number | null
  [KeyMetricsField.VAR]: number | null
}
export type KeyMetricsColumn = {
  valueFormatter: ScientValueFormatter
  cellStyle: ScientCellStyle
  field: Exclude<KeyMetricsField, KeyMetricsField.HEADER>
}
export type KeyMetrics = {
  rows: KeyMetricsRow[]
  columns: KeyMetricsColumn[]
  last_updated_at: string | null
}

export type RebalancingData = {
  key_metrics: KeyMetrics
  categories: RebalancingCategory[]
}

/** Define rebalancing data structure with targets */
export type PositionRowWithTargets = {
  [PositionRowField.BB_SYMBOL]: { value: string }
  [PositionRowField.DOLLAR_NMV]: { value: number }
  [PositionRowField.INCREMENTAL_DOLLAR]: {
    value: number | null
    edited?: boolean
    adjusted?: boolean
  }
  [PositionRowField.TARGET_DOLLAR]: {
    value: number | null
    edited?: boolean
    adjusted?: boolean
  }
  [PositionRowField.PERCENT_GMV]: { value: number }
  [PositionRowField.TARGET_PERCENT_GMV]: {
    value: number | null
    edited?: boolean
    adjusted?: boolean
  }
  [PositionRowField.STM]: { value: number | null }
}

export type PinnedBottomRowsWithTargets = {
  rowStyles: RowStyle[]
  rowData: PositionRowWithTargets[]
}

export type PositionDataWithTargets = {
  columns: PositionColumn[]
  rows: (PositionRowWithTargets | "")[]
  pinnedBottomRows: PinnedBottomRowsWithTargets
  last_updated_at: string | null
}

export type PositionsWithTargets = {
  long: PositionDataWithTargets
  short: PositionDataWithTargets
}

export type RebalancingCategoryWithTargets = {
  name: string
  positions: PositionsWithTargets
  summary: Summary
}

export type KeyMetricsRowWithTargets = {
  [KeyMetricsField.HEADER]: "Import" | "Target"
  [KeyMetricsField.GMV]: {
    value: number
    edited?: boolean
    adjusted?: boolean
  }
  [KeyMetricsField.NMV]: {
    value: number
    edited?: boolean
    adjusted?: boolean
  }
  [KeyMetricsField.NET_EXPOSURE]: {
    value: number | null
  }
  [KeyMetricsField.VAR]: {
    value: number | null
  }
}

export type KeyMetricsColumnWithTargets = {
  valueFormatter: ScientValueFormatter
  cellStyle: ScientCellStyle
  field: KeyMetricsField
}

export type KeyMetricsWithTargets = {
  rows: KeyMetricsRowWithTargets[]
  columns: KeyMetricsColumnWithTargets[]
  last_updated_at: string | null
}

export type RebalancingWithTargets = {
  key_metrics: KeyMetricsWithTargets
  categories: RebalancingCategoryWithTargets[]
}

export type PositionsResults = {
  bb_symbol: string
  dollar_delta: number | null
  target_dollar_delta: number | null
}

export type RebalancingResult = {
  positions: PositionsResults[]
}
