/** @jsxImportSource @emotion/react */
import { Divider } from "@blueprintjs/core"
import { useState } from "react"

import { IAccountBookPairs } from "../types"
import Exposures from "./Exposures"
import { RisksContainer, dividerCss } from "./Risks.styled"
import RisksHeader from "./RisksHeader"
import useRiskExposuresQuery from "./hooks/useRiskExposuresQuery"
import { FactorModelTimeZones, RiskType } from "./types"
import useRiskKeyMetricsQuery from "./hooks/useRiskKeyMetricsQuery"
import useFactorModelsQuery from "./hooks/useFactorModelsQuery"
import FactorModelTables from "./FactorModelTables"
import { WithLoading } from "../../../components/WithLoading"

interface IRiskProps {
  selectedFilters: IAccountBookPairs
}

const FactorModelTablesWithLoading = WithLoading(FactorModelTables)

const Risks = ({ selectedFilters }: IRiskProps) => {
  const [fullScreen, setFullScreen] = useState(false)
  const [selectedRisk, setSelectedRisk] = useState<RiskType | null>(null)
  const [selectedFactorModelTimezone, setSelectedFactorModelTimezone] =
    useState<FactorModelTimeZones | null>(FactorModelTimeZones.EUROPE_AFRICA)

  // Function to set the risk and clear the factor model (timezone)
  const handleRiskChange = (risk: RiskType) => {
    setSelectedRisk(risk)
    setSelectedFactorModelTimezone(null)
  }

  // Function to set the factor model (timezone) and clear the risk
  const handleFactorModelTzChange = (timezone: FactorModelTimeZones) => {
    setSelectedFactorModelTimezone(timezone)
    setSelectedRisk(null)
  }

  /**
   * Get riskExposures based on selectedFilters
   */
  const { riskExposures, isLoading: isLoadingExposures } = useRiskExposuresQuery({
    accountBookPairs: selectedFilters,
    type: selectedRisk,
  })

  /**
   * Get riskKeyMetrics based on selectedFilters
   */
  const {
    riskKeyMetrics,
    isLoading: isLoadingKeyMetrics,
    error,
  } = useRiskKeyMetricsQuery({
    accountBookPairs: selectedFilters,
  })

  const { factorModels, isLoading: isLoadingFactorModels } = useFactorModelsQuery()

  return (
    <RisksContainer flexDirection="column" justifyContent="center">
      <RisksHeader
        keyMetrics={riskKeyMetrics?.key_metrics}
        isLoading={isLoadingKeyMetrics}
        handleRiskChange={handleRiskChange}
        selectedRisk={selectedRisk}
        handleFactorModelTzChange={handleFactorModelTzChange}
        selectedFactorModelTimezone={selectedFactorModelTimezone}
      />
      <Divider css={dividerCss} />
      {selectedRisk !== null && (
        <Exposures
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
          exposures={riskExposures?.exposures}
          isLoading={isLoadingExposures}
          selectedFilters={selectedFilters}
        />
      )}
      {selectedFactorModelTimezone !== null && (
        <FactorModelTablesWithLoading
          factorModels={factorModels?.factor_models}
          selectedFactorModelTimezone={selectedFactorModelTimezone}
          isLoading={isLoadingFactorModels}
        />
      )}
    </RisksContainer>
  )
}

export default Risks
