import { InputGroup } from "@blueprintjs/core"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

export const loginInputCss = css({
  input: {
    backgroundColor: "white !important",
    color: "#2d333a !important",
    fontSize: "16px",
    padding: "18px 18px 18px 18px !important",
    width: "300px !important",
  },
})

export const tokenInputCss = css({
  input: {
    backgroundColor: "white !important",
    color: "#2d333a !important",
    fontSize: "16px",
    padding: "18px 18px 18px 18px !important",
    width: "200px !important",
    "::-webkit-inner-spin-button": { "-webkit-appearance": "none", margin: 0 },
    "::-webkit-outer-spin-button": { "-webkit-appearance": "none", margin: 0 },
  },
})

export const TokenInputPart = styled(InputGroup)({
  input: {
    backgroundColor: "white !important",
    fontSize: "18px",
    color: "black !important",
    textAlign: "center",
    height: "35px !important",
    width: "35px !important",
    marginRight: "12px",
  },
})

export const calloutLoginCss = css({
  color: "#2e58a5",
  fontStyle: "italic",
})
