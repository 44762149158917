import { useMemo } from "react"

import { formatValue } from "../../utils/formatValue"
import {
  KeyMetricsColumnWithTargets,
  KeyMetricsField,
  KeyMetricsRowWithTargets,
  KeyMetricsWithTargets,
} from "../types"

interface IuseRebalancingKeyMetricsDataProps {
  keyMetrics: KeyMetricsWithTargets
}

const rebalancingFormatValueOptions = (
  row: KeyMetricsRowWithTargets,
  col: KeyMetricsColumnWithTargets,
) => {
  if (col.field === KeyMetricsField.HEADER) {
    return {
      value: row[col.field],
      type: col.valueFormatter,
    }
  }

  return {
    value: row[col.field].value,
    type: col.valueFormatter,
    pctDecimal: 1,
    pctMinFractionDigits: 1,
    pctMaxFractionDigits: 1,
    floatMinFractionDigits: 1,
    floatMaxFractionDigits: 1,
  }
}

/**
 * Custom hook which format Rebalancing rows data
 */

const useRebalancingKeyMetricsTable = ({ keyMetrics }: IuseRebalancingKeyMetricsDataProps) => {
  const formattedRows = useMemo(() => {
    return keyMetrics.rows.map(row => {
      const formattedRow: { [key: string]: string | undefined } = {}
      keyMetrics.columns.forEach(col => {
        formattedRow[col.field] = formatValue(rebalancingFormatValueOptions(row, col))
      })
      return formattedRow
    })
  }, [keyMetrics])

  return { formattedRows }
}

export default useRebalancingKeyMetricsTable
