import styled from "@emotion/styled"
import { CSSProperties } from "react"
import { SCIENT_AGGRID_COLORS, SCIENT_COLORS } from "../../../../../styled/scientColors"

type CustomInputProps = {
  textAlign?: CSSProperties["textAlign"]
  index: number
  isEdited?: boolean
  isAdjusted?: boolean
}

export const CustomInput = styled.input<
  CustomInputProps & React.InputHTMLAttributes<HTMLInputElement>
>(props => ({
  textAlign: props.textAlign || "left", // Default text alignment
  width: "100%",
  height: "100%",
  padding: "0 10px",
  borderColor: SCIENT_COLORS.blue2,
  backgroundColor: props.isEdited
    ? SCIENT_COLORS.gold3
    : props.isAdjusted
      ? SCIENT_COLORS.orange3
      : props.index % 2 === 0 // Alternate row colors
        ? SCIENT_AGGRID_COLORS.defaultRowBackground
        : SCIENT_AGGRID_COLORS.altRowBackground,
}))
