import { Path } from "react-hook-form"

import { SCIENT_AGGRID_COLORS } from "../../../../../styled/scientColors"
import {
  HeaderCell,
  RowCell,
  TableContainer,
  TbodyContainer,
  TheadContainer,
  TrContainer,
} from "../../../../../styled/table.styled"
import { MediumText } from "../../../../../styled/text.styled"
import useRebalancingKeyMetricsTable from "../hooks/useRebalancingKeyMetricsTable"
import { KeyMetricsField, KeyMetricsWithTargets, RebalancingWithTargets } from "../types"
import { NumberInput } from "./NumberInput"

interface IRebalancingKeyMetricsTableProps {
  keyMetrics: KeyMetricsWithTargets
}

export const RebalancingKeyMetricsTable = ({ keyMetrics }: IRebalancingKeyMetricsTableProps) => {
  const { formattedRows } = useRebalancingKeyMetricsTable({ keyMetrics })

  return (
    <TableContainer gridTemplateColumns={`repeat(5, 1fr)`} width={"550px"}>
      <TheadContainer>
        <TrContainer>
          <th />
          {Object.keys(KeyMetricsField)
            .filter(colName => colName !== "HEADER")
            .map(colName => (
              <HeaderCell key={colName}>
                <MediumText color={SCIENT_AGGRID_COLORS.headerFont} fontWeight={"bold"}>
                  {KeyMetricsField[colName as keyof typeof KeyMetricsField]}
                </MediumText>
              </HeaderCell>
            ))}
        </TrContainer>
      </TheadContainer>

      <TbodyContainer>
        {formattedRows.map((row, index: number) =>
          row[KeyMetricsField.HEADER] === "Import" ? (
            <TrContainer key={index}>
              <HeaderCell>
                <MediumText color={SCIENT_AGGRID_COLORS.headerFont} fontWeight={"bold"}>
                  {row[KeyMetricsField.HEADER]}
                </MediumText>
              </HeaderCell>
              {Object.keys(KeyMetricsField)
                .filter(colName => colName !== "HEADER")
                .map(colName => (
                  <RowCell key={colName} index={index} justifyContent="center">
                    <MediumText>{row[colName]}</MediumText>
                  </RowCell>
                ))}
            </TrContainer>
          ) : (
            <TrContainer key={index}>
              <HeaderCell>
                <MediumText color={SCIENT_AGGRID_COLORS.headerFont} fontWeight={"bold"}>
                  {row[KeyMetricsField.HEADER]}
                </MediumText>
              </HeaderCell>

              <RowCell index={index} justifyContent="center" padding="0">
                <NumberInput
                  index={index}
                  name={
                    `key_metrics.rows[1].${KeyMetricsField.GMV}.value` as Path<RebalancingWithTargets>
                  }
                  importValueName={
                    `key_metrics.rows[0].${KeyMetricsField.GMV}.value` as Path<RebalancingWithTargets>
                  }
                  textAlign="center"
                />
              </RowCell>
              <RowCell index={index} justifyContent="center" padding="0">
                <NumberInput
                  index={index}
                  name={
                    `key_metrics.rows[1].${KeyMetricsField.NMV}.value` as Path<RebalancingWithTargets>
                  }
                  importValueName={
                    `key_metrics.rows[0].${KeyMetricsField.NMV}.value` as Path<RebalancingWithTargets>
                  }
                  allowNegativeValues={true}
                  textAlign="center"
                />
              </RowCell>
              <RowCell index={index} justifyContent="center">
                <MediumText>{row[KeyMetricsField.VAR]}</MediumText>
              </RowCell>
              <RowCell index={index} justifyContent="center">
                <MediumText>{row[KeyMetricsField.NET_EXPOSURE]}</MediumText>
              </RowCell>
            </TrContainer>
          ),
        )}
      </TbodyContainer>
    </TableContainer>
  )
}

RebalancingKeyMetricsTable.defaultProps = {
  keyMetrics: {
    columns: [],
    rows: [],
  },
}
