/** @jsxImportSource @emotion/react */
import { CSSProperties, useEffect, useState } from "react"
import { Path, useController, UseControllerProps, useFormContext } from "react-hook-form"

import { ErrorIconWithTooltip } from "../../Trading/components/ErrorIconWithTooltip"
import { getNumberValidationRegex } from "../../utils/getNumberValidationRegex"
import { RebalancingWithTargets } from "../types"
import { CustomInput } from "./Input.styled"

export interface IFloatInputProps extends UseControllerProps<RebalancingWithTargets> {
  index: number
  allowNegativeValues?: boolean
  onlyNegativeValues?: boolean
  displayedDecimal?: number
  textAlign?: CSSProperties["textAlign"]
  importValueName: Path<RebalancingWithTargets>
}

/**
 * Component to use with React Hook Form for Number Input.
 * Display value as string formated in en-GB locale.
 * Store value in react hook form state as numeric value.
 */

export const NumberInput = ({
  index,
  name,
  allowNegativeValues = false,
  onlyNegativeValues = false,
  displayedDecimal = 0, // number of decimal to display
  textAlign,
  importValueName,
}: IFloatInputProps) => {
  const [displayValue, setDisplayValue] = useState<string>("")

  const {
    field: { ref, value, onChange },
    fieldState: { error },
  } = useController({ name })

  const { setValue, watch } = useFormContext()

  // Get path of tracking keys
  const editedKey = name.replace(/value$/, "edited")
  const adjustedKey = name.replace(/value$/, "adjusted")

  // Get tracking keys
  const isEdited = watch(editedKey)
  const isAdjusted = watch(adjustedKey)

  // Get import value
  const importValue = watch(importValueName)
  const formattedImportValue = importValue
    ? parseFloat(importValue).toLocaleString("en-GB", {
        minimumFractionDigits: displayedDecimal,
        maximumFractionDigits: displayedDecimal,
      })
    : ""

  // Sync display value with form value
  useEffect(() => {
    if (!value) {
      setDisplayValue("")
    } else {
      setDisplayValue(
        value.toLocaleString("en-GB", {
          minimumFractionDigits: displayedDecimal,
          maximumFractionDigits: displayedDecimal,
        }),
      )
    }
  }, [value, displayedDecimal])

  // Restrict characters to number
  const allowedCharacters = getNumberValidationRegex(
    allowNegativeValues, // not allow negative value
    onlyNegativeValues, // not allow only negative value
    displayedDecimal,
  )

  // define the allowed special keys
  const specialKeys = ["Tab", "Backspace", "Delete", "ArrowLeft", "ArrowRight", "ArrowUp"]

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (allowedCharacters.test(inputValue)) {
      setDisplayValue(inputValue)
    }
  }

  const handleBlur = () => {
    const numericDisplayValue = parseFloat(displayValue)
    if (!isNaN(numericDisplayValue)) {
      // Format the numeric value for display
      const formattedDisplayValue = numericDisplayValue.toLocaleString("en-GB", {
        minimumFractionDigits: displayedDecimal,
        maximumFractionDigits: displayedDecimal,
      })
      // Always update displayValue with the formatted value
      setDisplayValue(formattedDisplayValue)

      if (!isAdjusted) {
        // Update form state only if the target value is different from the imported value
        if (formattedImportValue !== formattedDisplayValue) {
          onChange(numericDisplayValue)
          setValue(editedKey, true, { shouldDirty: false })
        } else {
          setValue(name, importValue, { shouldDirty: true }) // shouldDirty=true because shouldDirty has a strange behavior
          // set editedKey to false if value is equal to the formattedImportValue
          setValue(editedKey, false, { shouldDirty: false })
        }
      } else {
        setValue(name, numericDisplayValue, { shouldDirty: true })
        setValue(editedKey, true, { shouldDirty: false })
      }
    } else {
      if (!isAdjusted) {
        setValue(name, importValue, { shouldDirty: true })
        setDisplayValue(formattedImportValue)
        setValue(editedKey, false, { shouldDirty: false })
      } else {
        setDisplayValue(
          value.toLocaleString("en-GB", {
            minimumFractionDigits: displayedDecimal,
            maximumFractionDigits: displayedDecimal,
          }),
        )
        setValue(name, value, { shouldDirty: true })
        setValue(editedKey, false, { shouldDirty: false })
      }
    }
  }

  // Remove "en-GB" formating when focusing the input for editing
  const handleFocus = () => {
    setDisplayValue(prev => prev.replace(/,/g, ""))
  }

  return (
    <>
      <CustomInput
        ref={ref}
        type="text"
        value={displayValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={event => {
          if (
            !specialKeys.includes(event.key) &&
            !allowedCharacters.test(event.currentTarget.value + event.key) &&
            !allowedCharacters.test(event.key)
          ) {
            event.preventDefault()
          }
        }}
        index={index}
        textAlign={textAlign}
        isEdited={isEdited}
        isAdjusted={isAdjusted}
      />
      {error && <ErrorIconWithTooltip errorMessage={error.message} />}
    </>
  )
}
