import styled from "@emotion/styled"
import { SCIENT_COLORS } from "../../../../../styled/scientColors"

type ButtonProps = {
  withBorders?: boolean
  withLeftBorderRadius?: boolean
  withRightBorderRadius?: boolean
  isActive?: boolean
}

export const Button = styled.div<ButtonProps>(props => ({
  padding: "1px 10px 0 10px",
  fontSize: "12px",
  fontWeight: "bold",
  color: props.isActive ? "white" : `${SCIENT_COLORS.darkGray5} !important`,
  backgroundColor: props.isActive ? `${SCIENT_COLORS.darkGray5}` : "white",
  "&:hover": {
    color: "white !important",
    textDecoration: "none",
    backgroundColor: `${SCIENT_COLORS.darkGray5}`,
    cursor: "pointer",
  },
  // if more than 2 buttons, we add border left and right in Buttons in the middle in order to separate all buttons
  borderLeft: props.withBorders ? `solid 1px ${SCIENT_COLORS.darkGray5}` : "",
  borderRight: props.withBorders ? `solid 1px ${SCIENT_COLORS.darkGray5}` : "",
  // we add border radius arround the group of buttons
  borderTopLeftRadius: props.withLeftBorderRadius ? "2px" : "",
  borderBottomLeftRadius: props.withLeftBorderRadius ? "2px" : "",
  borderTopRightRadius: props.withRightBorderRadius ? "2px" : "",
  borderBottomRightRadius: props.withRightBorderRadius ? "2px" : "",
}))
