import { Divider, Intent, Spinner, SpinnerSize } from "@blueprintjs/core"

import WaveCreationForm from "./WaveCreationForm"
import WaveList from "./WaveList"
import { TradingWavePageContainer } from "./Trading.styled"
import { FetchNextPageOptions, InfiniteQueryObserverResult } from "@tanstack/react-query"
import { useInfiniteWaves } from "./hooks"
import WaveDetail from "./WaveDetail"
import { useEffect, useMemo, useState } from "react"
import { Wave } from "./types/wave"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SpinnerContainer } from "../../Ideas/List/Inbox/Inbox.styled"

const TradingBook = () => {
  /**
   * State to store the selected Wave
   */
  const [selectedWaveId, setSelectedWaveId] = useState<number | undefined>(undefined)

  /**
   * State to know if the flash trading page was successfully loaded for the first time.
   * Useful to prevent re-rendering the all page when fetching new waves
   */
  const [firstLoadingComplete, setFirstLoadingComplete] = useState(false)

  /**
   * Infinite hook to GET Trade Waves
   */
  const { waves, fetchNextPage, hasNextPage } = useInfiniteWaves()

  /**
   * Effect to set the firstLoadingComplete when we succesfully received
   * the first page of waves from the back.
   */
  useEffect(() => {
    if (waves?.length && !firstLoadingComplete) {
      setFirstLoadingComplete(true)
    }
  }, [waves?.length, firstLoadingComplete])

  const selectedWave = useMemo(
    () => waves?.find(wave => wave.id === selectedWaveId),
    [waves, selectedWaveId],
  )

  /**
   * If we have some waves and that no waves are selected,
   * we set the first one as selected by default
   */
  useEffect(() => {
    if (!selectedWaveId && !!waves?.length) {
      setSelectedWaveId(waves[0].id)
    }
  }, [waves, selectedWaveId])

  return (
    <TradingWavePageContainer flexDirection="column" gap="20px">
      <WaveCreationForm setSelectedWaveId={setSelectedWaveId} />
      <Divider />
      <FlexContainer gap="50px" alignItems="center" justifyContent="space-around">
        {firstLoadingComplete ? (
          <WaveList
            waves={waves}
            selectedWave={selectedWave}
            setSelectedWaveId={setSelectedWaveId}
            fetchNextPage={
              fetchNextPage as (
                options?: FetchNextPageOptions | undefined,
              ) => Promise<InfiniteQueryObserverResult<Wave[], unknown>>
            }
            hasNextPage={!!hasNextPage}
          />
        ) : (
          <SpinnerContainer justifyContent="center" alignItems="center" flexGrow={1}>
            <Spinner intent={Intent.PRIMARY} size={SpinnerSize.STANDARD} />
          </SpinnerContainer>
        )}
        <WaveDetail selectedWave={selectedWave} />
      </FlexContainer>
    </TradingWavePageContainer>
  )
}

export default TradingBook
