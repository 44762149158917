import { Alert, Intent } from "@blueprintjs/core"
import { Dispatch, SetStateAction, useState } from "react"

import { FlexContainer } from "../../../../../styled/flexbox.styled"
import { MediumText } from "../../../../../styled/text.styled"
import { RiskType } from "../../Risks/types"
import { RebalancingType } from "../types"
import { SectorButton } from "./SectorButtons.style"

interface ISectorButtons {
  selectedSector: Partial<RiskType>
  setSelectedSector: Dispatch<SetStateAction<RebalancingType>>
  isInProgress: boolean
}

/**
 * Scient app uses Blueprint Dark mode, which therefore applies to Button.
 * We want white buttons and
 * There's no equivalent of className="bp5-dark" for light mode.
 * So we have to customise Button with our own css.
 */
export const SectorButtons = ({
  selectedSector,
  setSelectedSector,
  isInProgress,
}: ISectorButtons) => {
  const [alert, setAlert] = useState(false)

  return (
    <FlexContainer>
      <Alert
        isOpen={alert}
        className="bp5-dark"
        canEscapeKeyCancel={true}
        canOutsideClickCancel={false}
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        icon="issue"
        intent={Intent.DANGER}
        onCancel={() => setAlert(false)}
        onClose={() => setAlert(false)}
        onConfirm={() => {
          selectedSector === RiskType.SECTOR_LEVEL_1
            ? setSelectedSector(RiskType.SECTOR_LEVEL_2)
            : setSelectedSector(RiskType.SECTOR_LEVEL_1)
          setAlert(false)
        }}
      >
        <MediumText>Are you sure you want to change sector level ?</MediumText>
        <MediumText>You will lose all your rebalancing in progress.</MediumText>
      </Alert>
      <SectorButton
        type="button"
        onClick={() =>
          isInProgress && selectedSector !== RiskType.SECTOR_LEVEL_1
            ? setAlert(true)
            : setSelectedSector(RiskType.SECTOR_LEVEL_1)
        }
        isActive={selectedSector === RiskType.SECTOR_LEVEL_1}
        withLeftBorderRadius
        withBorders
      >
        L1
      </SectorButton>
      <SectorButton
        type="button"
        onClick={() =>
          isInProgress && selectedSector !== RiskType.SECTOR_LEVEL_2
            ? setAlert(true)
            : setSelectedSector(RiskType.SECTOR_LEVEL_2)
        }
        isActive={selectedSector === RiskType.SECTOR_LEVEL_2}
        withRightBorderRadius
      >
        L2
      </SectorButton>
    </FlexContainer>
  )
}
