import { useMemo } from "react"

import { formatValue } from "../../utils/formatValue"
import { PositionColumn, PositionDataWithTargets, PositionRowWithTargets } from "../types"

interface IuseRebalancingDataProps {
  position: PositionDataWithTargets
  emptyRows: number
}

const rebalancingFormatValueOptions = (row: PositionRowWithTargets | "", col: PositionColumn) => {
  if (row === "") {
    // Handle case where row is an empty string
    return {
      value: null,
      type: col.valueFormatter,
      pctDecimal: 1,
      pctMinFractionDigits: 1,
      pctMaxFractionDigits: 1,
      floatMinFractionDigits: 1,
      floatMaxFractionDigits: 1,
    }
  }

  return {
    value: row[col.field].value,
    type: col.valueFormatter,
    pctDecimal: 1,
    pctMinFractionDigits: 1,
    pctMaxFractionDigits: 1,
    floatMinFractionDigits: 1,
    floatMaxFractionDigits: 1,
  }
}

/**
 * Custom hook which format Rebalancing rows data
 */

const useRebalancingTable = ({ position, emptyRows }: IuseRebalancingDataProps) => {
  const formattedRows = useMemo(() => {
    position.rows = position.rows.concat(new Array(emptyRows).fill(""))
    return position.rows.map(row => {
      const formattedRow: { [key: string]: string | undefined } = {}
      position.columns.forEach(col => {
        formattedRow[col.field] = formatValue(rebalancingFormatValueOptions(row, col))
      })
      return formattedRow
    })
  }, [emptyRows, position])

  const formattedPinnedBottomRows = useMemo(() => {
    return position.pinnedBottomRows.rowData.map(row => {
      const formattedPinnedBottomRow: { [key: string]: string | undefined } = {}
      position.columns.forEach(col => {
        formattedPinnedBottomRow[col.field] = formatValue(rebalancingFormatValueOptions(row, col))
      })
      return formattedPinnedBottomRow
    })
  }, [position])

  const firstColumn = useMemo(() => position.columns[0], [position.columns])
  const otherColumns = useMemo(
    () => position.columns.filter((_, index) => index !== 0),
    [position.columns],
  )

  return { formattedRows, formattedPinnedBottomRows, firstColumn, otherColumns }
}

export default useRebalancingTable
