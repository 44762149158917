import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useApi } from "../../../../hooks/useApi"

const useCreateRebalanceTradeMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (data: Blob) => void
  onError: (error: AxiosError) => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.trading.createRebalanceTrade, { onSuccess, onError })
}

export { useCreateRebalanceTradeMutation }
