import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"

export const FormContainer = styled.form({
  height: "100%",
})

export const RebalancingContainer = styled(FlexContainer)({
  gridArea: "content",
  height: "100%",
  width: "100%",
  overflow: "hidden",
})

export const dividerCss = css({
  margin: "2px 0",
})

export const CategoriesContainer = styled(FlexContainer)(props => ({
  overflow: "scroll",
  height: "100%",
  padding: "5px 100px 10px",
}))
