/**
 * Checks if there is at least one defined, non-null, and non-empty value for a specific key within the rows
 * of either the "long" or "short" positions in a given array of rebalancing categories.
 *
 * @param objArray - An array of `RebalancingCategoryWithTargets` objects, each containing positions (long and short)
 *                   and a summary of rebalancing data.
 * @param keyToCheck - The key within `PositionRowField` to check in each row of the specified side's positions.
 * @param side - Specifies whether to check in the "long" or "short" positions for each rebalancing category.
 *               Accepts either "long" or "short" as values.
 *
 * @returns `true` if at least one row in the specified side's positions has a value for the specified key that is
 *          defined, non-null, and non-empty; `false` otherwise.
 */

import { PositionRowField, RebalancingCategoryWithTargets } from "../types"

export const hasAtLeastOneFilledValueForSide = (
  objArray: RebalancingCategoryWithTargets[],
  keyToCheck: PositionRowField,
  side: "long" | "short",
): boolean => {
  return objArray.some(obj => {
    const positions = obj.positions?.[side]

    return (
      positions &&
      Array.isArray(positions.rows) &&
      positions.rows.some(row => {
        return (
          typeof row === "object" &&
          row !== null &&
          row[keyToCheck].value !== null &&
          row[keyToCheck].value !== undefined &&
          row[keyToCheck].value !== ""
        )
      })
    )
  })
}
