import { AxiosInstance } from "axios"

export type Sector = {
  id: number
  name: string
  level: number
  related_dataset_unit: string
}

export type Company = {
  id: number
  name: string
  slug: string
  bbg_ticker: string
}

export interface IWorldApi {
  getSectors: () => Promise<Sector[]>
  getCompanies: () => Promise<Company[]>
}

/**
 * Api routes for /api/v1/world/
 */
const world = (axiosClient: AxiosInstance): IWorldApi => ({
  /**
   * GET /api/v1/world/sectors/
   */
  getSectors: async () => {
    const { data } = await axiosClient.get<Sector[]>("world/sectors/")

    return data
  },

  /**
   * GET /api/v1/world/companies/
   */
  getCompanies: async () => {
    const { data } = await axiosClient.get<Company[]>("world/companies/")

    return data
  },
})

export default world
