import { ExecutionType, OrderType, Side } from "../types/common"
import { CSVColumnNames, CSVOrder } from "../types/form"

/**
 * Function to validate if there are invalid keys in the CSV.
 */

export const checkColumnsName = (csvData: CSVOrder[]) => {
  // Extract columns name from CSVColumnNames enum
  const expectedColumnsName = Object.values(CSVColumnNames)
  // Extract columns name from csvData
  const colNamesInCSV = Object.keys(csvData[0]) as (keyof CSVOrder)[]
  // check if there is unknow column in csvData
  const invalidKey = colNamesInCSV.filter(key => !expectedColumnsName.includes(key))

  return invalidKey.length ? invalidKey : false
}

/**
 * Functions to validate field's values
 */

export const validateInteger = (
  value: string,
  fieldName: string,
  setErrorMessage: (msg: string) => void,
): number | undefined => {
  if (value === "") return undefined // Allow empty fields
  const parsedValue = parseInt(value, 10)
  if (isNaN(parsedValue) || parsedValue.toString() !== value.toString()) {
    setErrorMessage(`CSV Error: Invalid value in column ${fieldName}: ${value}`)
    return undefined
  }
  return parsedValue
}

export const validateFloat = (
  value: string,
  fieldName: string,
  setErrorMessage: (msg: string) => void,
): string | undefined => {
  if (value === "") return undefined // Allow empty fields
  // Replace comma with dot and parse the float
  const parsedValue = parseFloat(value.replace(",", "."))
  // Check if parsedValue is a valid number
  if (isNaN(parsedValue)) {
    setErrorMessage(`CSV Error: Invalid value in column ${fieldName}: ${value}`)
    return undefined
  }
  // Return the value formatted to two decimal places as a string
  return parsedValue.toFixed(2)
}

export const validateCAP = (
  value: string,
  fieldName: string,
  setErrorMessage: (msg: string) => void,
): string => {
  if (value === "") return ""
  // Check if the value includes a percentage sign
  if (value.includes("%")) {
    const numericPart = value.replace("%", "").trim()
    const parsedValue = parseFloat(numericPart)
    // Validate if parsed value is a number
    if (isNaN(parsedValue)) {
      setErrorMessage(`CSV Error: Invalid CAP value for ${fieldName}: ${value}`)
      return ""
    }
    // Convert to decimal and limit to two decimal places
    return (parsedValue / 100).toFixed(2)
  } else {
    const parsedValue = parseFloat(value.replace(",", "."))
    // Validate if parsed value is a number
    if (isNaN(parsedValue)) {
      setErrorMessage(`CSV Error: Invalid value for ${fieldName}: ${value}`)
      return ""
    }
    // Limit to two decimal places
    return parsedValue.toFixed(2)
  }
}

export const validateExecutionType = (
  value: string,
  setErrorMessage: (msg: string) => void,
): ExecutionType => {
  if (value === "") return ExecutionType.HERE // Allow empty fields
  if (Object.values(ExecutionType).includes(value as ExecutionType)) {
    return value as ExecutionType // Return the value if it's a valid enum
  } else {
    setErrorMessage(
      `CSV Error: Invalid execution type: ${value}. Expected values are: ${Object.values(
        ExecutionType,
      ).join(", ")}`,
    )
    return ExecutionType.HERE
  }
}

export const validateOrderType = (
  value: string,
  setErrorMessage: (msg: string) => void,
): OrderType => {
  if (value === "") return OrderType.MARKET // Allow empty fields
  if (Object.values(OrderType).includes(value as OrderType)) {
    return value as OrderType // Return the value if it's a valid enum
  } else {
    setErrorMessage(
      `CSV Error: Invalid order type: ${value}. Expected values are: ${Object.values(
        OrderType,
      ).join(", ")}`,
    )
    return OrderType.MARKET
  }
}

export const validateSide = (value: string, setErrorMessage: (msg: string) => void): Side => {
  if (value === "") return Side.BUY // Allow empty fields
  if (Object.values(Side).includes(value as Side)) {
    return value as Side // Return the value if it's a valid enum
  } else {
    setErrorMessage(
      `CSV Error: Invalid side: ${value}. Expected values are: ${Object.values(Side).join(", ")}`,
    )
    return Side.BUY
  }
}

export const validateTargetEndTime = (
  value: string,
  setErrorMessage: (msg: string) => void,
): string => {
  if (value === "") return "" // Allow empty fields
  // Regex to match the hh:mm format
  const timeRegex = /^(?:[01]\d|2[0-3]):[0-5]\d$/
  if (timeRegex.test(value)) {
    return value // Return the value if it matches the format
  } else {
    setErrorMessage("CSV Error: Invalid time format for TARGET END. Expected format is hh:mm.")
    return ""
  }
}
