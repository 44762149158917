import { Button, Intent } from "@blueprintjs/core"
import React, { useRef } from "react"
import { HiddenInput } from "./UploadButton.styled"

interface IFileUploadButtonProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  intent?: Intent
  small?: boolean
  fill?: boolean
}

const UploadButton = ({ onChange, intent, small, fill }: IFileUploadButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <div>
      <Button onClick={handleButtonClick} intent={intent} small={small} fill={fill}>
        Upload CSV
      </Button>
      <HiddenInput type="file" ref={fileInputRef} accept=".csv" onChange={onChange} />
    </div>
  )
}

export default UploadButton
