import { AxiosInstance } from "axios"

export interface ISAPIApi {
  create_identity: ({
    code,
    code_verifier,
  }: {
    code: string
    code_verifier: string
  }) => Promise<null>
}

const sapi = (axiosClient: AxiosInstance): ISAPIApi => ({
  create_identity: async ({ code, code_verifier }: { code: string; code_verifier: string }) => {
    const response = await axiosClient.post("/sapi/identity/", { code, code_verifier })
    return response.data
  },
})

export default sapi
