import { ScientCellStyle, ScientValueFormatter } from "../../components/agGridMapping"
import {
  KeyMetricsField,
  KeyMetricsWithTargets,
  PositionColumn,
  PositionRow,
  PositionRowField,
  PositionRowWithTargets,
  RebalancingCategoryWithTargets,
  RebalancingData,
  RebalancingWithTargets,
} from "../types"

/**
 * This function is to initialize the rebalancing object to use it in the form
 */

export const initializeRebalancingForm = (rebalancing: RebalancingData): RebalancingWithTargets => {
  // Function to insert columns Target $ and Target % GMV
  const insertTargetColumns = (columns: PositionColumn[]) => {
    return columns.flatMap(column => {
      if (column.field === PositionRowField.DOLLAR_NMV) {
        return [
          column,
          {
            valueFormatter: ScientValueFormatter.INT_GB,
            field: PositionRowField.TARGET_DOLLAR,
          },
          {
            valueFormatter: ScientValueFormatter.INT_GB,
            field: PositionRowField.INCREMENTAL_DOLLAR,
          },
        ]
      } else if (column.field === PositionRowField.PERCENT_GMV) {
        return [
          column,
          {
            valueFormatter: ScientValueFormatter.PERCENTAGE,
            field: PositionRowField.TARGET_PERCENT_GMV,
          },
        ]
      } else {
        return [column]
      }
    })
  }

  // Function to insert fields Target $ and Target % GMV fields in position rows
  const updateRowsWithTargetFields = (
    rows: PositionRow[],
    isPinnedBottomRows = false,
  ): PositionRowWithTargets[] => {
    return rows.map(row => ({
      [PositionRowField.BB_SYMBOL]: { value: row[PositionRowField.BB_SYMBOL] },
      [PositionRowField.DOLLAR_NMV]: { value: row[PositionRowField.DOLLAR_NMV] },
      [PositionRowField.TARGET_DOLLAR]: isPinnedBottomRows
        ? { value: null }
        : { value: null, edited: false, adjusted: false },
      [PositionRowField.INCREMENTAL_DOLLAR]: isPinnedBottomRows
        ? { value: null }
        : { value: null, edited: false, adjusted: false },
      [PositionRowField.PERCENT_GMV]: { value: row[PositionRowField.PERCENT_GMV] },
      [PositionRowField.TARGET_PERCENT_GMV]: isPinnedBottomRows
        ? { value: null }
        : { value: null, edited: false, adjusted: false },
      [PositionRowField.STM]: { value: row[PositionRowField.STM] },
    }))
  }

  // Update key metrics
  const updatedKeyMetrics: KeyMetricsWithTargets = {
    ...rebalancing.key_metrics,
    columns: [
      {
        valueFormatter: ScientValueFormatter.TEXT,
        cellStyle: ScientCellStyle.MEDIUM_TEXT_CENTERED,
        field: KeyMetricsField.HEADER,
      },
      ...rebalancing.key_metrics.columns,
    ],
    rows: [
      {
        ...rebalancing.key_metrics.rows[0],
        [KeyMetricsField.HEADER]: "Import",
        [KeyMetricsField.GMV]: { value: rebalancing.key_metrics.rows[0][KeyMetricsField.GMV] },
        [KeyMetricsField.NMV]: { value: rebalancing.key_metrics.rows[0][KeyMetricsField.NMV] },
        [KeyMetricsField.NET_EXPOSURE]: {
          value: rebalancing.key_metrics.rows[0][KeyMetricsField.NET_EXPOSURE],
        },
        [KeyMetricsField.VAR]: { value: rebalancing.key_metrics.rows[0][KeyMetricsField.VAR] },
      },
      {
        [KeyMetricsField.HEADER]: "Target",
        [KeyMetricsField.GMV]: {
          // initialize with current GMV
          value: rebalancing.key_metrics.rows[0].GMV,
          edited: false,
          adjusted: false,
        },
        [KeyMetricsField.NMV]: {
          // initialize with current NMV
          value: rebalancing.key_metrics.rows[0].NMV,
          edited: false,
          adjusted: false,
        },
        [KeyMetricsField.NET_EXPOSURE]: { value: null },
        [KeyMetricsField.VAR]: { value: null },
      },
    ],
  }

  // Update categories
  const updatedCategories: RebalancingCategoryWithTargets[] = rebalancing.categories.map(
    category => {
      return {
        ...category,
        positions: {
          long: {
            ...category.positions.long,
            columns: insertTargetColumns(category.positions.long.columns),
            rows: updateRowsWithTargetFields(category.positions.long.rows),
            pinnedBottomRows: {
              ...category.positions.long.pinnedBottomRows,
              rowData: updateRowsWithTargetFields(
                category.positions.long.pinnedBottomRows.rowData,
                true,
              ),
            },
          },
          short: {
            ...category.positions.short,
            columns: insertTargetColumns(category.positions.short.columns),
            rows: updateRowsWithTargetFields(category.positions.short.rows),
            pinnedBottomRows: {
              ...category.positions.short.pinnedBottomRows,
              rowData: updateRowsWithTargetFields(
                category.positions.short.pinnedBottomRows.rowData,
                true,
              ),
            },
          },
        },
      }
    },
  )

  return {
    ...rebalancing,
    key_metrics: updatedKeyMetrics,
    categories: updatedCategories,
  }
}
