/**
 * Function to normalize the data in CSV of orders.
 *      _ All keys in upper case
 *      _ all values in lower case except values of ACCOUNT, BOOK and TICKER in upper case
 *      _ Remove empty columns (first one has an empty string "" as key, if more key starts by underscore _)
 */

import { CSVOrder } from "../types/form"

export const normalizeCSVData = (data: CSVOrder[]) => {
  return data.map(row => {
    return Object.fromEntries(
      Object.entries(row)
        // Remove empty keys and keys starting with '_'
        .filter(([key]) => key && !key.startsWith("_"))
        // Normalize keys to upper case
        .map(([key, value]) => {
          const normalizedKey = key.toUpperCase()

          let normalizedValue = value ? (value as string).toLowerCase() : value
          if (
            normalizedKey === "ACCOUNT" ||
            normalizedKey === "BOOK" ||
            normalizedKey === "TICKER"
          ) {
            normalizedValue = normalizedValue.toUpperCase()
          }

          return [normalizedKey, normalizedValue]
        }),
    )
  })
}
