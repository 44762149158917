import { css } from "@emotion/react"
import { UseControllerProps, UseFormWatch, useController } from "react-hook-form"

import { capitalizeFirstLetter } from "../../../../../utils"
import { Select } from "../../../../components"
import { WaveInputs } from "../types/form"
import { ErrorIconWithTooltip } from "./ErrorIconWithTooltip"
import { WaveSelectContainer } from "./WaveSelect.styled"

interface IWaveSelectProps extends UseControllerProps<WaveInputs> {
  optionsEnum: Record<string, string>
  onChange?: () => void
  watch: UseFormWatch<WaveInputs>
  index: number
}

export const WaveSelect = ({
  optionsEnum,
  control,
  name,
  index,
  onChange,
  watch,
  rules,
}: IWaveSelectProps) => {
  const { field, fieldState } = useController({ control, name, rules })

  // Retrieve ticker name in order to disable the Execution_type Select if ticker ends by 'INDEX'
  const ticker = watch(`orders[${index}].symbol.ticker`)

  //Create select options from the enum
  const options = Object.values(optionsEnum).map(value => ({
    label: capitalizeFirstLetter(value.replace(/_/g, " ")),
    value: value,
  }))

  /**
   * Handle the change of the select
   */
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    // Update the field value
    field.onChange(event.target.value)
    // Call the provided onChange function if it exists
    if (onChange) {
      onChange()
    }
  }

  const customCss = css({
    /* font color when select is disabled */
    "select.bp5-disabled, select[disabled]": {
      color: "white !important",
    },
    /* Hide icon when select is disabled */
    ".bp5-html-select.bp5-disabled > .bp5-icon, .bp5-html-select > .bp5-icon.bp5-disabled, select:disabled + .bp5-icon":
      {
        display: "none !important",
      },
  })

  return (
    <WaveSelectContainer alignItems="center">
      <Select
        noDivWrapper={true}
        selectProps={{
          onChange: handleChange,
          options: options,
          value: watch(name) || "", // use 'watch(name)' instead of 'field.value because field.value might not reflect immediately the state when it is updated with setValue
          fill: true,
          minimal: true,
          iconName: "caret-down",
          disabled: name.includes("execution_type") && ticker.endsWith("INDEX"),
          ref: field.ref, // Optional because we use here an Controlled Component, but added it because can help with proper form registration and improve accessibility
        }}
        customCss={customCss}
      />
      {fieldState.error && <ErrorIconWithTooltip errorMessage={fieldState.error.message} />}
    </WaveSelectContainer>
  )
}
