import { useApi } from "../../../hooks/useApi"
import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"

export const useCreateidentityMutation = ({
  onError,
  onSuccess,
}: {
  onError: (error: AxiosError) => void
  onSuccess: () => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.sapi.create_identity, { onError, onSuccess })
}
