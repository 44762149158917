// @ts-nocheck
import { MenuItem } from "@blueprintjs/core"
import { Suggest } from "@blueprintjs/select"
import useCompanies from "../hooks/useCompanies"
/**
 * Reusable Suggest component to select a company from
 * the companies list.
 */
const CompanySuggest = ({ selectedCompany, onCompanySelect, placeholder }) => {
  const { companies } = useCompanies()

  const renderCompany = (company, { handleClick, modifiers }) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={company.slug}
        onClick={handleClick}
        text={company.name}
      />
    )
  }

  const searchCompany = (query, companies) => {
    return companies.filter(
      company =>
        company.name.toLowerCase().includes(query.toLowerCase()) ||
        company.bbg_ticker.toLowerCase().includes(query.toLowerCase()),
    )
  }

  const noResults = (
    <MenuItem active={false} disabled={true} icon="zoom-out" text="No Companies found" />
  )

  return (
    <>
      {companies && (
        <Suggest
          items={companies}
          inputValueRenderer={company => company.name}
          itemRenderer={renderCompany}
          itemListPredicate={searchCompany}
          onItemSelect={onCompanySelect}
          noResults={noResults}
          fill={true}
          inputProps={{ placeholder }}
          selectedItem={selectedCompany}
          resetOnSelect={true}
        />
      )}
    </>
  )
}

export default CompanySuggest
