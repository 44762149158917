import { AgGridReact } from "ag-grid-react"
import { useMemo } from "react"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { DataframeData } from "../types"
import { AgGridContainer } from "./PortfolioKeyMetrics.styled"
import { useAgGrid } from "./hooks/useAgGrid"
import { KeyMetricsWithTargets } from "../Rebalancing/types"

interface IPortfolioKeyMetricsProps {
  keyMetrics: DataframeData | KeyMetricsWithTargets
  isLoading?: boolean
}

export const PortfolioKeyMetrics = ({ keyMetrics, isLoading }: IPortfolioKeyMetricsProps) => {
  const { columnsData } = useAgGrid({
    columns: keyMetrics?.columns,
  })

  /**
   * AgGridContainer width is set up by the number of columns multiply by the colWidth,
   * to be able to view all columns and to avoid empty space in the grid.
   */
  const COL_WIDTH = 120

  const defaultColDef = useMemo(() => {
    return {
      width: COL_WIDTH,
      suppressMenu: true,
      // Class are defined in ag-grid.scss file and are needed to style header
      headerClass: "sct-ag-medium-text-header sct-ag-center-header",
    }
  }, [])

  return (
    <FlexContainer>
      <AgGridContainer
        className="ag-theme-balham-dark"
        width={(columnsData.length * COL_WIDTH).toString()}
      >
        {!isLoading && (
          <AgGridReact
            defaultColDef={defaultColDef}
            columnDefs={columnsData}
            rowData={keyMetrics?.rows}
            headerHeight={28}
            rowHeight={32}
            suppressCellFocus={true}
            suppressContextMenu
            suppressHorizontalScroll
            suppressRowHoverHighlight
          />
        )}
      </AgGridContainer>
    </FlexContainer>
  )
}

PortfolioKeyMetrics.defaultProps = {
  keyMetrics: {
    columns: [],
    rows: [],
  },
}
