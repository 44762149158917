import { AxiosInstance } from "axios"

export type bloombergData = { datetime: string; value: number }

export interface IBloombergAPI {
  getBloombergData: ({ query }: { query: string }) => Promise<bloombergData[]>
  requestNewInstruments: ({ tickers }: { tickers: string[] }) => Promise<void>
}

const bloomberg = (axiosClient: AxiosInstance): IBloombergAPI => ({
  /**
   * GET /api/v1/bloomberg/data/
   */
  getBloombergData: async ({ query }: { query: string }) => {
    const { data } = await axiosClient.get<bloombergData[]>("bloomberg/data/", {
      params: { ticker: query },
    })

    return data
  },

  /**
   * POST /api/v1/bloomberg/instruments/
   */
  requestNewInstruments: async ({ tickers }: { tickers: string[] }) => {
    await axiosClient.post("bloomberg/instruments/", { tickers })
  },
})

export default bloomberg
