import { useQuery } from "@tanstack/react-query"
import { useMemo } from "react"
import { useApi } from "../../../../hooks/useApi"

const useBooksQuery = (
  { accountIds }: { accountIds?: number[] } = {},
  { enabled = true } = {}, // Allow passing enabled option. True by default
) => {
  const { scientApi } = useApi()

  const { data, error, isLoading } = useQuery(
    ["tradingbooks", accountIds],
    () => scientApi.trading.getBooks({ accountIds }),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false, // to avoid rerender when there's no books associated to an user
      enabled,
    },
  )

  return {
    books: useMemo(() => data || [], [data]),
    isLoading: enabled ? isLoading : false, // hack because when 'enabled' is false, 'isLoading' is true instead of false
    error,
  }
}

export default useBooksQuery
