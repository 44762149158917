import Big from "big.js"

import {
  KeyMetricsField,
  KeyMetricsWithTargets,
  PositionRowField,
  PositionRowWithTargets,
  RebalancingCategoryWithTargets,
  RebalancingWithTargets,
} from "../types"
import { hasAtLeastOneFilledValueForSide } from "./hasAtLeastOneFilledValueForKey"
import { sumFieldValues } from "./sumFieldValues"

/**
 * Function to adjust the Portfolio, when user specifies a targetGMV and/or a targetNMV,
 * and/or target % GMV of position.
 * If all target % GMV of a  given side are null, undefined or empty string, then this side is not adjusted.
 */
export const adjustPortfolio = (rebalancing: RebalancingWithTargets): RebalancingWithTargets => {
  // Extract KeyMetrics values from Target row
  const keyMetricsTargetRow = rebalancing.key_metrics.rows.find(row => row.header === "Target")
  //  Use the Non-null Assertion Operator '!' because in adjustPortfolio we are certain that targets GMV and NMV exist
  const TARGET_GMV = keyMetricsTargetRow!.GMV.value
  const TARGET_NMV = keyMetricsTargetRow!.NMV.value

  // calculate targets of long market value (LMV) and short market value (SMV)
  const targetLMV = (TARGET_GMV + TARGET_NMV) / 2
  const targetSMV = -(TARGET_GMV - TARGET_NMV) / 2

  // Calculate the LMV and SMV implied by the Target % GMV (if not null, else we use the % GMV).
  const impliedLMV = calculateImpliedValue(
    rebalancing,
    "long",
    TARGET_GMV,
    PositionRowField.TARGET_PERCENT_GMV,
    PositionRowField.PERCENT_GMV,
  )
  const impliedSMV = calculateImpliedValue(
    rebalancing,
    "short",
    TARGET_GMV,
    PositionRowField.TARGET_PERCENT_GMV,
    PositionRowField.PERCENT_GMV,
  )

  // Calculate ratios to adjust the Target % GMV
  const longRatio = targetLMV / impliedLMV

  const shortRatio = targetSMV / impliedSMV

  // Check if user has speficied at least one target in key metrics
  const hasKeyMetricsTarget = checkIfKeyMetricsTarget(rebalancing.key_metrics)

  // Check for each side if there's at least one target specified
  const hasLongTarget = hasAtLeastOneFilledValueForSide(
    rebalancing.categories,
    PositionRowField.TARGET_PERCENT_GMV,
    "long",
  )
  const hasShortTarget = hasAtLeastOneFilledValueForSide(
    rebalancing.categories,
    PositionRowField.TARGET_PERCENT_GMV,
    "short",
  )

  // Adjusted traking key 'edited' and 'adjusted'
  const rebalancingWithAdjustedKey: RebalancingWithTargets = {
    key_metrics: {
      ...rebalancing.key_metrics,
      rows: rebalancing.key_metrics.rows.map(row => {
        if (row.header === "Target") {
          // When edited=true, set adjusted=true and edited=false
          return {
            ...row,
            GMV: row.GMV.edited ? { ...row.GMV, edited: false, adjusted: true } : row.GMV,
            NMV: row.NMV.edited ? { ...row.NMV, edited: false, adjusted: true } : row.NMV,
          }
        }
        return row
      }),
    },
    categories: rebalancing.categories.map(cat => ({
      ...cat,
      positions: {
        ...cat.positions,
        long: {
          ...cat.positions.long,
          rows: setTrackingKeys(cat.positions.long.rows),
        },
        short: {
          ...cat.positions.short,
          rows: setTrackingKeys(cat.positions.short.rows),
        },
      },
    })),
  }

  // Update Target % GMV according ratios
  const rebalancingWithAdjustedTargetPercentGMV: RebalancingWithTargets = {
    ...rebalancingWithAdjustedKey,
    categories: rebalancingWithAdjustedKey.categories.map(cat => ({
      ...cat,
      positions: {
        ...cat.positions,
        long: {
          ...cat.positions.long,
          rows:
            hasLongTarget || hasKeyMetricsTarget
              ? applyRatioToTargetPercent(cat.positions.long.rows, longRatio)
              : [...cat.positions.long.rows],
        },
        short: {
          ...cat.positions.short,
          rows:
            hasShortTarget || hasKeyMetricsTarget
              ? applyRatioToTargetPercent(cat.positions.short.rows, shortRatio)
              : [...cat.positions.short.rows],
        },
      },
    })),
  }

  // Calculate the target $ according target %
  const rebalancingWithAdjustedTargetDollar: RebalancingWithTargets = {
    ...rebalancingWithAdjustedTargetPercentGMV,
    categories: rebalancingWithAdjustedTargetPercentGMV.categories.map(cat => {
      return {
        ...cat,
        positions: {
          ...cat.positions,
          long: {
            ...cat.positions.long,
            rows:
              hasLongTarget || hasKeyMetricsTarget
                ? adjustTargetDollar(cat.positions.long.rows, TARGET_GMV)
                : [...cat.positions.long.rows],
          },
          short: {
            ...cat.positions.short,
            rows:
              hasShortTarget || hasKeyMetricsTarget
                ? adjustTargetDollar(cat.positions.short.rows, TARGET_GMV)
                : [...cat.positions.short.rows],
          },
        },
      }
    }),
  }

  // Then, adjust the Pinned Bottom Rows
  const rebalancingWithAdjustedPinnedBottomRows: RebalancingWithTargets = {
    ...rebalancingWithAdjustedTargetDollar,
    categories: rebalancingWithAdjustedTargetDollar.categories.map(cat => {
      const adjustedLongPinnedBottomRows = adjustPinnedBottomRows(
        cat.positions.long.pinnedBottomRows.rowData,
        cat.positions.long.rows,
      )

      const adjustedShortPinnedBottomRows = adjustPinnedBottomRows(
        cat.positions.short.pinnedBottomRows.rowData,
        cat.positions.short.rows,
      )

      return {
        ...cat,
        positions: {
          ...cat.positions,
          long: {
            ...cat.positions.long,
            pinnedBottomRows: {
              ...cat.positions.long.pinnedBottomRows,
              rowData:
                hasLongTarget || hasKeyMetricsTarget
                  ? adjustedLongPinnedBottomRows
                  : [...cat.positions.long.pinnedBottomRows.rowData],
            },
          },
          short: {
            ...cat.positions.short,
            pinnedBottomRows: {
              ...cat.positions.short.pinnedBottomRows,
              rowData:
                hasShortTarget || hasKeyMetricsTarget
                  ? adjustedShortPinnedBottomRows
                  : [...cat.positions.short.pinnedBottomRows.rowData],
            },
          },
        },
      }
    }),
  }

  // Finally, calculate the summary of each category
  const rebalancingWithAdjustedSummary: RebalancingWithTargets = {
    ...rebalancingWithAdjustedPinnedBottomRows,
    categories: rebalancingWithAdjustedPinnedBottomRows.categories.map(cat => {
      return {
        ...cat,
        summary: {
          [PositionRowField.PERCENT_GMV]: calculateSummaryValues(cat)[PositionRowField.PERCENT_GMV],
          [KeyMetricsField.NMV]: calculateSummaryValues(cat)[KeyMetricsField.NMV],
        },
      }
    }),
  }

  return rebalancingWithAdjustedSummary
}

/**
 * Function to calculate the total market value for a given side.
 * It gives the LMV or SMV.
 * If field1 if not a valid number, then if provided the function use field2
 */
const calculateImpliedValue = (
  rebalancing: RebalancingWithTargets,
  side: "long" | "short",
  targetGMV: number,
  field1: string,
  field2?: string,
): number => {
  const totalValue = rebalancing.categories.reduce((acc, cat) => {
    // Sum the field values for the current category
    const sum = sumFieldValues({
      rows: cat.positions[side].rows,
      field1,
      field2,
    })

    return acc.plus(Big(sum))
  }, Big(0))

  return totalValue.times(targetGMV).toNumber()
}

/**
 * Function to apply the ratio to the Target % GMV
 */
const applyRatioToTargetPercent = (rows: (PositionRowWithTargets | "")[], ratio: number) =>
  rows.map(row => {
    if (row && typeof row === "object") {
      return {
        ...row,
        [PositionRowField.TARGET_PERCENT_GMV]: {
          ...row[PositionRowField.TARGET_PERCENT_GMV],
          value:
            ((row[PositionRowField.TARGET_PERCENT_GMV].value ??
              row[PositionRowField.PERCENT_GMV].value) ||
              0) * ratio,
        },
      }
    }
    return row
  })

/**
 *  Function to adjust Target $ according target % GMV
 */
const adjustTargetDollar = (rows: (PositionRowWithTargets | "")[], targetGmv: number) =>
  rows.map(row => {
    if (row && typeof row === "object") {
      const targetDollar = (row[PositionRowField.TARGET_PERCENT_GMV].value || 0) * targetGmv

      return {
        ...row,
        [PositionRowField.TARGET_DOLLAR]: {
          ...row[PositionRowField.TARGET_DOLLAR],
          value: targetDollar,
        },
      }
    }
    return row
  })

/**
 *  Function to calculate the pinned bottom rows
 */
const adjustPinnedBottomRows = (
  rowData: PositionRowWithTargets[],
  rows: (PositionRowWithTargets | "")[],
) =>
  rowData.map(row => {
    if (row && typeof row === "object") {
      const newTargetGMVPercentage = sumFieldValues({
        rows,
        field1: PositionRowField.TARGET_PERCENT_GMV,
      })

      const newTargetDollar = sumFieldValues({
        rows,
        field1: PositionRowField.TARGET_DOLLAR,
      })

      return {
        ...row,
        [PositionRowField.TARGET_PERCENT_GMV]: {
          ...row[PositionRowField.TARGET_PERCENT_GMV],
          value: newTargetGMVPercentage,
        },
        [PositionRowField.TARGET_DOLLAR]: {
          ...row[PositionRowField.TARGET_DOLLAR],
          value: newTargetDollar,
        },
      }
    }
    return row
  })

/**
 * This function calculs summary for a given category
 */
const calculateSummaryValues = (category: RebalancingCategoryWithTargets) => {
  const longPinnedBottomRow = category.positions.long.pinnedBottomRows.rowData[0]
  const shortPinnedBottomRow = category.positions.short.pinnedBottomRows.rowData[0]

  // Calculate % GMV
  const longTargetGMVPercent =
    longPinnedBottomRow[PositionRowField.TARGET_PERCENT_GMV].value ??
    longPinnedBottomRow[PositionRowField.PERCENT_GMV].value
  const shortTargetGMVPercent =
    shortPinnedBottomRow[PositionRowField.TARGET_PERCENT_GMV].value ??
    shortPinnedBottomRow[PositionRowField.PERCENT_GMV].value
  const percentGMV = Big(longTargetGMVPercent).minus(Math.abs(shortTargetGMVPercent))

  // Calculate NMV
  const longTargetNMV =
    longPinnedBottomRow[PositionRowField.TARGET_DOLLAR].value ??
    longPinnedBottomRow[PositionRowField.DOLLAR_NMV].value
  const shortTargetNMV =
    shortPinnedBottomRow[PositionRowField.TARGET_DOLLAR].value ??
    shortPinnedBottomRow[PositionRowField.DOLLAR_NMV].value
  const NMV = Big(longTargetNMV).minus(Math.abs(shortTargetNMV))

  // Return the calculated values
  return {
    [PositionRowField.PERCENT_GMV]: percentGMV.toNumber(),
    [KeyMetricsField.NMV]: NMV.toNumber(),
  }
}

const checkIfKeyMetricsTarget = (keyMetrics: KeyMetricsWithTargets): boolean => {
  // Find Import and Target rows
  // Used the Non-null Assertion (!) because we are certain importRow and targetRow exist
  const importRow = keyMetrics.rows.find(row => row.header === "Import")!
  const targetRow = keyMetrics.rows.find(row => row.header === "Target")!

  // Return true if either GMV or NMV import is different from the respective target
  if (importRow.GMV.value !== targetRow.GMV.value || importRow.NMV.value !== targetRow.NMV.value) {
    return true
  }

  // Return false if both GMV and NMV import are equal to the respective targets
  return false
}

// Function to set adjusted=true and edited=false for editable field where edited=true
const setTrackingKeys = (rows: (PositionRowWithTargets | "")[]) => {
  return rows.map(row => {
    if (row && typeof row === "object") {
      let updatedRow = { ...row }
      // Iterate over each key-value pair in row
      Object.entries(updatedRow).forEach(([key, fieldValue]) => {
        // Check if the fieldValue is an object and has the 'edited' and 'adjusted' property
        if (
          fieldValue &&
          typeof fieldValue === "object" &&
          "edited" in fieldValue &&
          "adjusted" in fieldValue &&
          fieldValue.edited === true
        ) {
          updatedRow = {
            ...updatedRow,
            [key]: {
              ...fieldValue,
              edited: false,
              adjusted: true, // Mark as adjusted
            },
          }
        }
      })

      return updatedRow
    }
    return row
  })
}
