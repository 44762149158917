/** @jsxImportSource @emotion/react */
import { Divider, Intent, Spinner, SpinnerSize } from "@blueprintjs/core"
import { AxiosError } from "axios"
import { isEmpty } from "lodash"
import { Dispatch, KeyboardEvent, SetStateAction, useCallback, useState } from "react"
import { useFormContext } from "react-hook-form"

import { LargeText } from "../../../../styled/text.styled"
import { createToaster } from "../../../utils/createToaster"
import { SpinnerContainer } from "../../Ideas/List/Inbox/Inbox.styled"
import { RiskType } from "../Risks/types"
import { IAccountBookPairs } from "../types"
import Category from "./Category"
import { useCreateRebalanceTradeMutation } from "./hooks/useCreateRebalanceMutation"
import {
  CategoriesContainer,
  dividerCss,
  FormContainer,
  RebalancingContainer,
} from "./Rebalancing.styled"
import RebalancingHeader from "./RebalancingHeader"
import { RebalancingWithTargets } from "./types"
import { convertToRebalancingResult } from "./utils/formatRebalancing"

interface IRebalancingDataProps {
  selectedFilters: IAccountBookPairs
  selectedSector: RiskType
  setSelectedSector: Dispatch<SetStateAction<RiskType>>
  isLoading: boolean
}

const Rebalancing = ({
  selectedFilters,
  selectedSector,
  setSelectedSector,
  isLoading,
}: IRebalancingDataProps) => {
  const [rebalancingInProgress, setRebalancingInProgress] = useState(false)

  // Acces the form method and state with useFormContext
  const {
    handleSubmit,
    formState: { errors },
    watch,
  } = useFormContext<RebalancingWithTargets>()

  // Access categories
  const categories = watch("categories")
  // Access key_metrics
  const keyMetrics = watch("key_metrics")

  // Create rebalance trade
  const createTradeMutation = useCreateRebalanceTradeMutation({
    onSuccess: blob => {
      createToaster({
        message: "Trade CSV file generates with success",
        intent: Intent.SUCCESS,
      })
      // Create a link element
      const link = document.createElement("a")
      // Create a URL for the blob and set it as the href
      const url = URL.createObjectURL(blob)
      link.href = url
      // Set the download attribute with a filename
      link.download = "rebalance_orders.csv"
      // Append the link to the document body
      document.body.appendChild(link)
      // Programmatically click the link to trigger the download
      link.click()
      // Clean up
      link.remove()
      URL.revokeObjectURL(url)
      setRebalancingInProgress(false)
    },

    onError: (error: AxiosError) => {
      //Set type assertion
      const errorData = error.response?.data as {
        error?: { __all__?: string[] }
      }
      // Retrieve custom error from Axios response or generic Axios error.message
      const errorMessage = errorData?.error?.__all__ || error.message
      createToaster({
        message: errorMessage
          ? `Error: ${errorMessage}`
          : "An error occurred: Failed to download CSV",
        intent: Intent.DANGER,
      })
    },
  })

  // Callback to submit Form
  const submitForm = useCallback(
    (data: RebalancingWithTargets) => {
      const results = convertToRebalancingResult(data)
      createTradeMutation.mutate(results)
    },
    [createTradeMutation],
  )

  // Function to not allow wave submission when user presses 'enter' key
  const handleKeyPress = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()
    }
  }

  if (!isEmpty(errors)) {
    createToaster({
      message: "An error occurred: please refresh the page.",
      intent: Intent.DANGER,
    })
  }

  return (
    <FormContainer onSubmit={handleSubmit(submitForm)} onKeyDown={handleKeyPress}>
      <RebalancingContainer flexDirection="column">
        {!isLoading && (
          <RebalancingHeader
            keyMetrics={keyMetrics}
            rebalancingInProgress={rebalancingInProgress}
            setRebalancingInProgress={setRebalancingInProgress}
            selectedFilters={selectedFilters}
            selectedSector={selectedSector}
            setSelectedSector={setSelectedSector}
            isLoading={isLoading}
          />
        )}
        <Divider css={dividerCss} />
        {!isLoading ? (
          !categories?.length ? (
            <CategoriesContainer justifyContent="center">
              <LargeText>There are no positions for the selected book(s)</LargeText>
            </CategoriesContainer>
          ) : (
            <CategoriesContainer flexDirection="column" gap="35px">
              {categories.map((category, index) => (
                <Category
                  key={category.name}
                  category={category}
                  categoryIndex={index}
                  isLoading={isLoading}
                />
              ))}
            </CategoriesContainer>
          )
        ) : (
          <SpinnerContainer justifyContent="center" alignItems="center" flexGrow={1}>
            <Spinner intent={Intent.PRIMARY} size={SpinnerSize.STANDARD} />
          </SpinnerContainer>
        )}
      </RebalancingContainer>
    </FormContainer>
  )
}

export default Rebalancing
