import { OrderInputs, WaveInputs } from "../Trading/types/form"
import { Order } from "../Trading/types/order"
import { Wave } from "../Trading/types/wave"
import { isDefaultOrder } from "./checkEmptyWave"
import { localTimeToDateTimeStringUTC } from "./localTimeToDateTimeStringUTC"

export const convertWaveInputsToWave = ({ orders, ...rest }: WaveInputs): Partial<Wave> => {
  return {
    orders: orders.filter(input => !isDefaultOrder(input)).map(convertOrderInputsToOrder),
    ...rest,
  }
}

/**
 * Function to format the order data of ReactHookForm in order to send it to back-end.
 *      _ 'symbol' in data is an object of type ITickerItem = { ticker: string, isNew?: boolean }
 *        Back-end is waiting for the ticker name in string
 *
 *      _ 'target_end_datetime' in data is local time in hours:minutes.
 *        Back-end is waiting for Iso String Date in UTC.
 *        Function localTimeToDateTimeStringUTC is used to get the Iso date
 */

export const convertOrderInputsToOrder = ({
  id, // React Hook Form id is removed before sending the wave
  symbol,
  target_end_datetime,
  book_id,
  ...rest
}: OrderInputs): Order => {
  if (book_id === undefined) {
    // Should not happen as book_id is required in the form
    throw new Error("Book id is required")
  }

  return {
    book_id: book_id,
    symbol: symbol.ticker,
    target_end_datetime: target_end_datetime
      ? localTimeToDateTimeStringUTC(target_end_datetime)
      : undefined,
    ...rest,
  }
}
