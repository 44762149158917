import { Button, Icon } from "@blueprintjs/core"
import type { IconName } from "@blueprintjs/core"
import {
  AbsoluteFilterIconContainer,
  RelativeFilterIconContainer,
} from "../../Ideas/List/Inbox/InboxFilters.styled"
import { NocFilterItemContainer } from "./NocFilters.styled"
import { isEmpty } from "lodash"

interface IFilterProps {
  iconName: IconName
  value: any
  onClear: () => void
  children: React.ReactNode
}

const FilterItem = ({ iconName, value, onClear, children }: IFilterProps) => (
  <>
    <RelativeFilterIconContainer>
      <Icon icon="filter" />
      <AbsoluteFilterIconContainer>
        <Icon icon={iconName} size={12} />
      </AbsoluteFilterIconContainer>
    </RelativeFilterIconContainer>
    <NocFilterItemContainer gap="10px">{children}</NocFilterItemContainer>
    {!isEmpty(value) && <Button icon="cross" onClick={onClear} fill={false} />}
  </>
)

export default FilterItem
