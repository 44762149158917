/** @jsxImportSource @emotion/react */
import { Icon } from "@blueprintjs/core"
import TinyMCEFullscreenIcon from "../../../../icons/TinuMCEFullScreenIcon"
import { FlexContainer, FlexItem } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { LargeText, SmallText } from "../../../../styled/text.styled"
import { HeaderContainer } from "../components/Header.styled"
import { PortfolioKeyMetrics } from "../components/PortfolioKeyMetrics"
import { DataframeData } from "../types"
import { ButtonGroup } from "./components/ButtonGroup"
import { SimpleRiskSelectorButton } from "./components/SimpleRiskSelectorButton"
import { RiskTypeInfo } from "./enums"
import { FactorModelTimeZones, RiskType } from "./types"

const sectorOptions = [
  { label: "L1", value: RiskType.SECTOR_LEVEL_1 },
  { label: "L2", value: RiskType.SECTOR_LEVEL_2 },
  { label: "L3", value: RiskType.SECTOR_LEVEL_3 },
]

const timeZoneOptions = [
  { label: "EU", value: FactorModelTimeZones.EUROPE_AFRICA },
  { label: "US", value: FactorModelTimeZones.AMERICAS },
  { label: "AS", value: FactorModelTimeZones.ASIA_OCENIA },
]
interface IRisksHeaderProps {
  keyMetrics?: DataframeData
  isLoading?: boolean
  handleRiskChange: (risk: RiskType) => void
  selectedRisk: RiskType | null
  handleFactorModelTzChange: (factorModelTz: FactorModelTimeZones) => void
  selectedFactorModelTimezone: FactorModelTimeZones | null
}

const RisksHeader = ({
  keyMetrics,
  isLoading,
  selectedRisk,
  handleRiskChange,
  selectedFactorModelTimezone,
  handleFactorModelTzChange,
}: IRisksHeaderProps) => {
  const sectorRiskInfo = RiskTypeInfo[RiskType.SECTOR_LEVEL_1]
  const SectorIcon = sectorRiskInfo.iconComponent

  return (
    <HeaderContainer flexDirection="row-reverse" justifyContent="space-between" gap="20px">
      <PortfolioKeyMetrics keyMetrics={keyMetrics} isLoading={isLoading} />
      <FlexContainer flexDirection="column" justifyContent="space-between">
        <FlexContainer gap="50px">
          <FlexContainer flexDirection="column" alignItems="center">
            <SmallText>Sectors</SmallText>
            <SectorIcon {...sectorRiskInfo.iconProps} />
            <ButtonGroup
              handleChange={handleRiskChange}
              options={sectorOptions}
              selected={selectedRisk}
            />
          </FlexContainer>

          {Object.entries(RiskTypeInfo)
            .filter(([key]) => !sectorOptions.map(sector => sector.value).includes(key as RiskType))
            .map(([key, { iconComponent: Icon, label, iconProps }]) => (
              <SimpleRiskSelectorButton
                key={key}
                icon={<Icon {...iconProps} />}
                label={label}
                onClick={() => handleRiskChange(key as RiskType)}
                isActive={selectedRisk === key}
              />
            ))}
          <FlexContainer flexDirection="column" alignItems="center">
            <SmallText>Factor Models</SmallText>
            <Icon icon="model" size={24} style={{ margin: "0 0 6px" }} />
            <ButtonGroup
              handleChange={handleFactorModelTzChange}
              options={timeZoneOptions}
              selected={selectedFactorModelTimezone}
            />
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </HeaderContainer>
  )
}
export default RisksHeader
