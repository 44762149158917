import { Spinner } from "@blueprintjs/core"

import { MediumItalicText } from "../../../../styled/text.styled"
import { DataframeData } from "../types"
import { NoDataMessage, OrdersContainer } from "./Orders.styled"
import Grid from "../components/Grid"
import { RefreshButton } from "./components/RefreshButton"
import useIntervalDateDistance from "./hooks/useIntervalDateDistance"

interface IOrdersProps {
  orders?: DataframeData
  isLoading: boolean
}

const Orders = ({ orders, isLoading }: IOrdersProps) => {
  const ordersDateDistance = useIntervalDateDistance({
    date: orders?.last_updated_at,
    interval: 60 * 1000,
  })

  return (
    /**
     * OrdersContainer has flexGrow={1} to take all the remaining space in height.
     */
    <OrdersContainer flexGrow={1} alignItems="center" justifyContent="center">
      {orders ? (
        <Grid rows={orders.rows} columns={orders.columns} label="Orders">
          {ordersDateDistance && (
            <>
              <MediumItalicText>Last updated {ordersDateDistance}</MediumItalicText>
              <RefreshButton dateDistance={ordersDateDistance} />
            </>
          )}
        </Grid>
      ) : isLoading ? (
        <Spinner />
      ) : (
        <NoDataMessage>No orders available, please select an account and/or a book</NoDataMessage>
      )}
    </OrdersContainer>
  )
}

export default Orders
