import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useApi } from "../../../hooks/useApi"

const useRequestNewInstrumentsMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void
  onError: (error: AxiosError) => void
}) => {
  const { scientApi } = useApi()
  return useMutation(scientApi.bloomberg.requestNewInstruments, { onSuccess, onError })
}

export { useRequestNewInstrumentsMutation }
