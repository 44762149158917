import { FieldValues } from "react-hook-form"
import { ExecutionType, ITickerItem, OrderType, Side } from "./common"

export type OrderInputs = {
  /**
   * useFieldArray automatically generates a unique identifier named id which is used for key prop.
   * For more information why this is required: https://react.dev/learn/rendering-lists
   * id is remove by the system before sending wave
   */
  id?: string
  book_id?: number
  symbol: ITickerItem
  side: Side
  amount_usd?: number
  quantity?: number
  order_type: OrderType
  limit_price?: string
  execution_type: ExecutionType
  execution_cap_pct?: string
  time_in_market?: number
  target_end_datetime: string
  execution_details: string
}

export type WaveInputs = FieldValues & {
  user_id: number
  execution_summary: string
  rational: string
  account_id: number
  orders: OrderInputs[]
}

export enum CSVColumnNames {
  ACCOUNT = "ACCOUNT",
  BOOK = "BOOK",
  TICKER = "TICKER",
  SIDE = "SIDE",
  USD = "USD",
  QTY = "QTY",
  TYPE = "TYPE",
  LIMIT = "LIMIT",
  EXECUTION = "EXECUTION",
  CAP = "CAP",
  TIME_IN_MARKET = "TIME IN MARKET",
  TARGET_END = "TARGET END",
  COMMENT = "COMMENT",
}

export type CSVOrder = {
  [CSVColumnNames.ACCOUNT]?: string
  [CSVColumnNames.BOOK]?: string
  [CSVColumnNames.TICKER]?: ITickerItem
  [CSVColumnNames.SIDE]?: Side
  [CSVColumnNames.USD]?: string
  [CSVColumnNames.QTY]?: string
  [CSVColumnNames.TYPE]?: OrderType
  [CSVColumnNames.LIMIT]?: string
  [CSVColumnNames.EXECUTION]?: ExecutionType
  [CSVColumnNames.CAP]?: string
  [CSVColumnNames.TIME_IN_MARKET]?: string
  [CSVColumnNames.TARGET_END]?: string
  [CSVColumnNames.COMMENT]?: string
}
