import { FlexContainer } from "../../../../styled/flexbox.styled"
import { useWindowDimensions } from "../../../hooks"
import CategorySummary from "./CategorySummary"
import PositionTable from "./PositionsTable"
import { RebalancingCategoryWithTargets } from "./types"

interface ICategoriesProps {
  category: RebalancingCategoryWithTargets
  categoryIndex: number
  isLoading?: boolean
}

const Category = ({ category, categoryIndex }: ICategoriesProps) => {
  //  Retrieve screen's width to have responsive tables
  const { width } = useWindowDimensions()

  // Calculate the number of empty rows to add in the shortest table to match the longest table length
  let emptyLongRows = 0
  let emptyShortRows = 0
  if (category.positions.long.rows.length > category.positions.short.rows.length) {
    emptyShortRows = category.positions.long.rows.length - category.positions.short.rows.length
  } else if (category.positions.short.rows.length > category.positions.long.rows.length) {
    emptyLongRows = category.positions.short.rows.length - category.positions.long.rows.length
  }

  return (
    <FlexContainer flexDirection="column" gap="10px">
      <CategorySummary category={category} />
      <FlexContainer
        gap={width > 1200 ? "150px" : "20px"}
        flexDirection={width > 1200 ? "row" : "column"}
      >
        {/* Position long */}
        <PositionTable
          position={category?.positions.long}
          emptyRows={emptyLongRows}
          categoryIndex={categoryIndex}
          isLong
        />
        {/* Position short */}
        <PositionTable
          position={category?.positions.short}
          emptyRows={emptyShortRows}
          categoryIndex={categoryIndex}
        />
      </FlexContainer>
    </FlexContainer>
  )
}

export default Category
