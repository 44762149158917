/** @jsxImportSource @emotion/react */

import { Position, Tag, Tooltip } from "@blueprintjs/core"
import { Fragment } from "react"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { capitalizeFirstLetter, exposeFirstXStringFromList } from "../../../../utils"
import { tagCss, TickerListText } from "./WaveTickerList.styled"
import { Side } from "./types/common"
import { Order } from "./types/order"

interface IWaveOrdersProps {
  orders: Order[]
  isWaveDetail?: boolean
}

const WaveTickerList = ({ orders, isWaveDetail }: IWaveOrdersProps) => {
  return (
    <FlexContainer flexDirection="column" gap="5px">
      <SideTickerBlock
        sides={[Side.BUY, Side.BUY_COVER]}
        orders={orders}
        isWaveDetail={isWaveDetail}
      />
      <SideTickerBlock
        sides={[Side.SELL, Side.SELL_SHORT]}
        orders={orders}
        isWaveDetail={isWaveDetail}
      />
    </FlexContainer>
  )
}

const SideTickerBlock = ({
  sides,
  orders,
  isWaveDetail,
}: {
  sides: Side[]
  orders: Order[]
  isWaveDetail?: boolean
}) => (
  <FlexContainer gap="5px" alignItems="center">
    {sides.map(
      side =>
        orders.some(order => order.side === side) && (
          <Fragment key={side}>
            <Tag minimal large={isWaveDetail} css={tagCss({ isWaveDetail, side })}>
              {capitalizeFirstLetter(side.replace("_", " "))}
            </Tag>
            <Tooltip
              compact
              minimal
              position={Position.BOTTOM}
              disabled={isWaveDetail || orders.filter(order => order.side === side).length <= 2}
              content={orders
                .filter(order => order.side === side)
                .map(order => order.symbol)
                .join(", ")}
            >
              <TickerListText>
                {isWaveDetail
                  ? orders
                      .filter(order => order.side === side)
                      .map(order => order.symbol)
                      .join(", ")
                  : exposeFirstXStringFromList(
                      orders.filter(order => order.side === side).map(order => order.symbol),
                      2,
                    )}
              </TickerListText>
            </Tooltip>
          </Fragment>
        ),
    )}
  </FlexContainer>
)

export default WaveTickerList
