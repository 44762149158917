/** @jsxImportSource @emotion/react */
import { H3 } from "@blueprintjs/core"
import { format, parseISO } from "date-fns"

import { FlexContainer } from "../../../../styled/flexbox.styled"
import { SCIENT_COLORS } from "../../../../styled/scientColors"
import { LargeText, MediumText, noMarginCss } from "../../../../styled/text.styled"
import { waveStatusColor } from "../utils/waveStatusColor"
import { Wave } from "./types/wave"
import {
  HeaderContainer,
  MainContainer,
  NoWaveDetailPlaceholder,
  SummaryContainer,
  TickerListContainer,
} from "./WaveDetail.styled"
import { DetailContainer } from "./WaveList.styled"
import WaveTickerList from "./WaveTickerList"

interface IWaveDetailProps {
  selectedWave: Wave | undefined
}

const WaveDetail = ({ selectedWave }: IWaveDetailProps) => {
  if (!selectedWave) {
    return (
      <DetailContainer>
        <NoWaveDetailPlaceholder alignItems="center" justifyContent="center">
          <LargeText>No Previous Waves selected</LargeText>
        </NoWaveDetailPlaceholder>
      </DetailContainer>
    )
  }

  return (
    <DetailContainer>
      <MainContainer flexDirection="column" alignItems="center" gap="20px">
        <HeaderContainer justifyContent="space-around" alignItems="center">
          <FlexContainer gap="5px">
            <LargeText>Status:</LargeText>
            <LargeText color={waveStatusColor(selectedWave.status)}>
              {selectedWave.status}
            </LargeText>
          </FlexContainer>
          <H3 css={noMarginCss}>Wave {selectedWave.id}</H3>
          <FlexContainer flexDirection="column" gap="5px">
            <MediumText>{`Created ${
              selectedWave.created_at ? format(parseISO(selectedWave.created_at), "dd-MM-yyyy") : ""
            }`}</MediumText>
            {selectedWave.updated_at && (
              <MediumText>{`Updated ${format(
                parseISO(selectedWave.updated_at),
                "dd-MM-yyyy",
              )}`}</MediumText>
            )}
          </FlexContainer>
        </HeaderContainer>

        <TickerListContainer gap="8px" justifyContent="center">
          <WaveTickerList orders={selectedWave.orders} isWaveDetail />
        </TickerListContainer>

        <SummaryContainer>
          <MediumText whiteSpace="pre-line" color={SCIENT_COLORS.black}>
            {selectedWave.execution_summary}
          </MediumText>
        </SummaryContainer>
      </MainContainer>
    </DetailContainer>
  )
}
export default WaveDetail
