/** @jsxImportSource @emotion/react */

import { Button, Intent, Spinner, SpinnerSize } from "@blueprintjs/core"
import QRCode from "qrcode"
import { useEffect, useState } from "react"

import { FlexContainer } from "../../../styled/flexbox.styled"
import { LargeText, MediumItalicText } from "../../../styled/text.styled"
import { OTPMachineState } from "../../AuthProvider"
import { ScientLogoNew } from "../../components/ScientLogo/ScientLogoNew"
import { useAuth } from "../../hooks"
import { useGenerateOTPMutation, useValidateOTPMutation } from "../../hooks/useOtpMutation"
import { toaster } from "../../utils/createToaster"
import { TransitionContainer, loginSubmitButtonCss } from "./Login.styled"
import TokenInput from "./TokenInput"

const SetupOTP = () => {
  const { setOtpMachineState, logout, profile } = useAuth()
  const [qrcodeUrl, setQRCodeUrl] = useState("")

  const [tokenParts, setTokenParts] = useState(Array(6).fill(""))

  const { mutate: mutateGenerateOtp, isLoading: isLoadingGenerateOtp } = useGenerateOTPMutation({
    onSuccess: data => {
      if (data.otp_auth_url) {
        QRCode.toDataURL(data.otp_auth_url).then(setQRCodeUrl)
      }
    },
    onError: () => {
      toaster.show({
        message: "Error generating QR Code, please retry.",
        intent: "danger",
      })
    },
  })

  const { mutate: mutateValidateOtp } = useValidateOTPMutation({
    onSuccess: () => {
      toaster.show({
        message:
          "You have successfully setup 2FA. For security reasons, you have been logged out. Please log in again.",
        intent: "success",
      })
      setOtpMachineState && setOtpMachineState(OTPMachineState.WAITING)
      logout && logout()
    },
    onError: () => {
      toaster.show({
        message:
          "Invalid token, please try again. If you are having trouble, please contact support.",
        intent: "danger",
      })
    },
  })

  useEffect(() => {
    if (profile) {
      if (!profile.otp?.otp_auth_url) {
        mutateGenerateOtp()
      } else {
        QRCode.toDataURL(profile.otp.otp_auth_url).then(setQRCodeUrl)
      }
    }
  }, [mutateGenerateOtp, profile])

  const onSubmit = () => {
    mutateValidateOtp(tokenParts.join(""))
  }

  // Submit if 6 digits are entered
  useEffect(() => {
    if (tokenParts.join("").length === 6) {
      mutateValidateOtp(tokenParts.join(""))
    }
  }, [mutateValidateOtp, tokenParts])

  return (
    <TransitionContainer flexDirection="column" alignItems="center" gap="20px">
      <form onSubmit={onSubmit}>
        <FlexContainer flexDirection="column" gap="15px" alignItems="center">
          <ScientLogoNew height="60px" />
          <LargeText color="#2e58a5">Set up 2FA</LargeText>
          <MediumItalicText color="#2e58a5">
            Scan the QR Code with your favorite Authenticator Application on your mobile.
          </MediumItalicText>
          {isLoadingGenerateOtp ? (
            <Spinner intent={Intent.PRIMARY} size={SpinnerSize.STANDARD} />
          ) : (
            <img src={qrcodeUrl} alt="QR Code" />
          )}
          <TokenInput tokenParts={tokenParts} setTokenParts={setTokenParts} />
          <Button
            fill
            intent="primary"
            rightIcon="log-in"
            type="submit"
            disabled={tokenParts.join("").length !== 6}
            css={loginSubmitButtonCss}
          >
            Validate Token
          </Button>
        </FlexContainer>
      </form>
    </TransitionContainer>
  )
}

export default SetupOTP
