/** @jsxImportSource @emotion/react */
import { Dialog, Spinner } from "@blueprintjs/core"
import { RiskType, RiskZoomDataType } from "../types"
import useRiskZoomQuery from "../hooks/useRiskZoomQuery"
import { IAccountBookPairs } from "../../types"
import Grid from "../../components/Grid"
import { RiskSpinnerContainer, RiskZoomContainer, RiskZoomDialogCss } from "./RiskZoomDialog.styled"
import { RiskTypeInfo } from "../enums"

interface IRiskZoomDialogProps {
  type: RiskType
  category: string // value for query
  categoryLabel: string // label for dialog
  isOpen: boolean
  onClose: () => void
  selectedFilters: IAccountBookPairs
}

const RiskZoomDialog = ({
  type,
  isOpen,
  onClose,
  category,
  categoryLabel,
  selectedFilters,
}: IRiskZoomDialogProps) => {
  const Icon = RiskTypeInfo[type].iconComponent
  const iconProps = RiskTypeInfo[type].iconProps

  const { riskZoom, isLoading } = useRiskZoomQuery({
    type,
    accountBookPairs: selectedFilters,
    category,
    // Search enabled only for styles and when category is not empty
    enabled: type === RiskType.STYLES || category !== "",
  })

  const getTitle = (type: RiskType, categoryLabel: string) => {
    let label = RiskTypeInfo[type].label
    if (categoryLabel) {
      label += " - " + categoryLabel
    }
    return label
  }

  /**
   * Render dialog content based on riskZoom data
   * @returns Spinner if loading
   * @returns Simple grid for styles zoom
   * @returns FlexContainer with two Grids if data_type is LONG_SHORT
   */
  const renderDialogContent = () => {
    if (isLoading || !riskZoom) {
      return (
        <RiskSpinnerContainer alignItems="center" justifyContent="center">
          <Spinner />
        </RiskSpinnerContainer>
      )
    }
    if (riskZoom.data_type === RiskZoomDataType.LONG_SHORT) {
      return (
        <RiskZoomContainer gap="10px">
          <Grid label="LONG" rows={riskZoom.data.long.rows} columns={riskZoom.data.long.columns} />
          <Grid
            label="SHORT"
            rows={riskZoom.data.short.rows}
            columns={riskZoom.data.short.columns}
          />
        </RiskZoomContainer>
      )
    } else return <Grid rows={riskZoom.data.rows} columns={riskZoom.data.columns} />
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={getTitle(type, categoryLabel)}
      icon={<Icon {...iconProps} />}
      className="bp5-dark"
      css={RiskZoomDialogCss}
    >
      {renderDialogContent()}
    </Dialog>
  )
}

export default RiskZoomDialog
