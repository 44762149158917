import { Dispatch, SetStateAction } from "react"
import { IUser } from "../../../../api/index"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { OmniSuggestLight } from "../../../components/OmniSuggestLight"
import UserSuggest from "../../../components/UserSuggest"
import { FilterSearchInputContainer } from "../../Ideas/List/Inbox/InboxFilters.styled"
import { FieldType } from "../types/business"
import MetricSelection from "./MetricSelection"
import { NocFilterItemContainer, NocFiltersContainer, StickyContainer } from "./NocFilters.styled"
import SectorSuggest from "../../../components/SectorSuggest"
import CompanySuggest from "../../../components/CompanySuggest"
import FilterItem from "./FilterItem"

interface INocFiltersProps {
  userFilter: IUser | null
  stockFilter: { id: number; bloomberg_code: string } | null
  companyFilter: { id: number; name: string } | null
  sectorFilter: { id: number; name: string } | null
  setUserFilter: Dispatch<SetStateAction<IUser | null>>
  setStockFilter: Dispatch<SetStateAction<{ id: number; bloomberg_code: string } | null>>
  setCompanyFilter: Dispatch<SetStateAction<{ id: number; name: string } | null>>
  setSectorFilter: Dispatch<SetStateAction<{ id: number; name: string } | null>>
  seenMetrics: FieldType[]
  setSeenMetrics: Dispatch<SetStateAction<Array<FieldType>>>
}

const NocFilters = ({
  userFilter,
  stockFilter,
  companyFilter,
  sectorFilter,
  setUserFilter,
  setStockFilter,
  setCompanyFilter,
  setSectorFilter,
  seenMetrics,
  setSeenMetrics,
}: INocFiltersProps) => {
  return (
    <StickyContainer>
      <NocFiltersContainer gap="50px" justifyContent="flex-start" alignItems="center">
        <FlexContainer gap="15px" alignItems="center">
          <FilterItem iconName="dollar" value={stockFilter} onClear={() => setStockFilter(null)}>
            <FilterSearchInputContainer flexGrow={1}>
              <OmniSuggestLight
                exclude={{ ds: true, dsf: true, rf: true }}
                inputValueRenderer={item => item.bloomberg_code}
                // @ts-ignore
                onItemSelect={item => setStockFilter(item)}
                placeholder="Filter by Stock"
                selectedItem={stockFilter}
              />
            </FilterSearchInputContainer>
          </FilterItem>
          <FilterItem iconName="person" value={userFilter} onClear={() => setUserFilter(null)}>
            <FilterSearchInputContainer flexGrow={1} flexDirection="row-reverse">
              <UserSuggest
                selectedUser={userFilter}
                // @ts-ignore
                onUserSelect={user => setUserFilter(user)}
                placeholder="Filter by Analyst"
              />
            </FilterSearchInputContainer>
          </FilterItem>
          <FilterItem
            iconName="briefcase"
            value={sectorFilter}
            onClear={() => setSectorFilter(null)}
          >
            <NocFilterItemContainer gap="10px">
              <SectorSuggest
                selectedSector={sectorFilter}
                // @ts-ignore
                onSectorSelect={sector => setSectorFilter(sector)}
                placeholder="Filter by Sector"
              />
            </NocFilterItemContainer>
          </FilterItem>
          <FilterItem
            iconName="office"
            value={companyFilter}
            onClear={() => setCompanyFilter(null)}
          >
            <NocFilterItemContainer gap="10px">
              <CompanySuggest
                selectedCompany={companyFilter}
                // @ts-ignore
                onCompanySelect={company => setCompanyFilter(company)}
                placeholder="Filter by Company"
              />
            </NocFilterItemContainer>
          </FilterItem>
        </FlexContainer>
        <MetricSelection seenMetrics={seenMetrics} setSeenMetrics={setSeenMetrics} />
      </NocFiltersContainer>
    </StickyContainer>
  )
}
export default NocFilters
