import { Button, MenuItem, Spinner } from "@blueprintjs/core"
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select"
import { useController, UseControllerProps } from "react-hook-form"

import { FlexContainerAndItem } from "../../../../styled/flexbox.styled"
import { ToastError } from "../../../components"
import { SectorLevel, TradingSector, TradingStock } from "../Stock/types"
import useTradingSectors from "../hooks/useTradingSectors"

interface ISectorsProps extends UseControllerProps<TradingStock> {
  level: SectorLevel
}

export const SectorSelect = ({ level, control, name }: ISectorsProps) => {
  const {
    field: { value, onChange },
    fieldState: { isDirty },
  } = useController<TradingStock>({ control, name })

  /**
   * Get all sectors
   */
  const { sectors, error, isLoading } = useTradingSectors()

  /**
   * generate Menu to render in the dropdown
   */
  const renderSectors: ItemRenderer<TradingSector> = (sector, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) return null
    return (
      <MenuItem
        active={modifiers.active}
        key={sector.id}
        text={sector.name}
        onClick={handleClick}
        roleStructure="listoption"
      />
    )
  }

  /**
   * Filter the sector based on the query
   */
  const filterSector: ItemPredicate<TradingSector> = (query, item, index, exactMatch) => {
    const normalizedQuery = query.toLowerCase()
    if (!item.name) {
      return false
    }
    const normalizedTitle = item.name?.toLowerCase()

    if (exactMatch) {
      return normalizedTitle === normalizedQuery
    } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0
    }
  }

  return (
    <FlexContainerAndItem justifyContent="center" flexGrow={1}>
      {isLoading ? (
        <Spinner size={15} />
      ) : (
        <Select
          items={sectors?.length ? sectors.filter(sector => sector.level === level) : []}
          itemRenderer={renderSectors}
          itemPredicate={filterSector}
          onItemSelect={sector => onChange(sector)}
          noResults={<MenuItem disabled={true} text="No Sectors found" />}
          resetOnSelect={true}
          resetOnClose={true}
          disabled={error ? true : false}
          fill
        >
          <Button
            fill
            small
            intent={isDirty ? "warning" : "none"}
            text={
              (value as TradingSector)?.name ? (value as TradingSector).name : "Select a sector"
            }
            rightIcon="caret-down"
          />
        </Select>
      )}
      <ToastError
        error={error}
        errorMessage="Unable to get sectors, please try to refresh the page."
      />
    </FlexContainerAndItem>
  )
}

export default SectorSelect
