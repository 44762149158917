import React, { useEffect, useRef } from "react"

import { FlexContainer } from "../../../styled/flexbox.styled"
import { TokenInputPart } from "./LoginTextInput.styled"

interface TokenInputProps {
  tokenParts: string[]
  setTokenParts: React.Dispatch<React.SetStateAction<string[]>>
}

// const TokenInput: React.FC<TokenInputProps> = (
const TokenInput = ({ tokenParts, setTokenParts }: TokenInputProps) => {
  const inputRefs = useRef<Array<HTMLInputElement | null>>([])

  // Focus on the first input or the last input based on token length
  useEffect(() => {
    const token = tokenParts.join("")
    if (token.length === 6) {
      // Focus on the last input if 6 digits are entered
      inputRefs.current[5]?.focus()
    } else if (token.length === 0) {
      // Focus on the first input if no digits are entered
      inputRefs.current[0]?.focus()
    }
  }, [tokenParts])

  const handlePartChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    // Allow only digits and limit to 1 character
    if (/^\d?$/.test(value)) {
      const newTokenParts = [...tokenParts]
      newTokenParts[index] = value
      setTokenParts(newTokenParts)

      // Move focus to the next input
      if (value && index < tokenParts.length - 1) {
        inputRefs.current[index + 1]?.focus()
      }
    }
  }

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    // Handle numeric key input
    if (event.key >= "0" && event.key <= "9") {
      const newTokenParts = [...tokenParts]
      newTokenParts[index] = event.key // Replace with the new digit
      setTokenParts(newTokenParts)

      // Move to the next input if not the last one
      if (index < tokenParts.length - 1) {
        inputRefs.current[index + 1]?.focus()
      }
      event.preventDefault()
    }

    if (event.key === "Backspace") {
      const newTokenParts = [...tokenParts]
      if (newTokenParts[index]) {
        newTokenParts[index] = ""
        setTokenParts(newTokenParts)
      } else if (index > 0) {
        newTokenParts[index - 1] = ""
        setTokenParts(newTokenParts)
        inputRefs.current[index - 1]?.focus()
      }
      event.preventDefault()
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    const pastedText = event.clipboardData.getData("text").replace(/\s/g, "") // Remove whitespace
    const newTokenParts = pastedText
      .split("")
      .filter(char => /^\d$/.test(char)) // Keep only digits
      .slice(0, 6) // Limit to 6 characters

    setTokenParts(current => {
      const updatedParts = [...current]
      newTokenParts.forEach((char, i) => {
        if (i < updatedParts.length) updatedParts[i] = char
      })
      return updatedParts
    })
  }

  return (
    <FlexContainer>
      {tokenParts.map((part, index) => (
        <TokenInputPart
          key={index}
          inputRef={el => (inputRefs.current[index] = el)}
          value={part}
          onChange={event => handlePartChange(index, event)}
          onKeyDown={event => handleKeyDown(index, event)}
          onPaste={handlePaste}
        />
      ))}
    </FlexContainer>
  )
}

export default TokenInput
