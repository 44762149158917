import { Button, Divider, Icon } from "@blueprintjs/core"
import { format, parseISO } from "date-fns"
import { useCallback } from "react"

import { useState } from "react"
import { FlexContainer } from "../../../styled/flexbox.styled"
import { MediumText } from "../../../styled/text.styled"
import { capitalizeFirstLetter } from "../../../utils"
import {
  AnalystNameItem,
  CompanyNameItem,
  HeaderContainer,
  LastLineContainer,
  ModelUpdatesCardContainer,
  TickerItem,
  YearsContainer,
} from "./ModelUpdatesCard.styled"
import YearPeriods from "./YearPeriods"
import ChartContainer from "./charting/NocChart"
import { FieldType, ModelUpdate } from "./types/business"
interface IModelUpdatesCard {
  card: ModelUpdate
  seenMetrics: string[]
}
const ModelUpdatesCard = ({ card, seenMetrics }: IModelUpdatesCard) => {
  const [openChartDialog, setOpenChartDialog] = useState(false)
  const [selectedMetric, setSelectedMetric] = useState(FieldType.KEY_PROFIT.toString())
  const [selectedUpdate, setSelectedUpdate] = useState(null)

  const getAvailablePeriods = useCallback(() => {
    const periods: Array<string> = []
    card.years.forEach((year, index) => {
      if (year.period) periods.push(year.period)
      year.half_years.forEach((half_year, index) => {
        if (half_year.period) periods.push(half_year.period)
      })
      year.quarters.forEach((quarter, index) => {
        if (quarter.period) periods.push(quarter.period)
      })
    })
    return periods
  }, [card])

  const [selectedPeriod, setSelectedPeriod] = useState(getAvailablePeriods()[0])

  const handleClick = useCallback(() => {
    setOpenChartDialog(true)
  }, [setOpenChartDialog])

  return (
    <ModelUpdatesCardContainer flexDirection="row-reverse" gap="5px">
      <YearsContainer
        flexDirection="column"
        flexGrow={8}
        flexBasis={1000}
        justifyContent="center"
        gap="12px"
      >
        {card.years.map((year, index) => {
          return (
            <YearPeriods
              key={`${card.model_updated_at}-${index}`}
              year={year}
              upper_key={`${card.model_updated_at}-${index}`}
              seenMetrics={seenMetrics}
              setOpenChartDialog={setOpenChartDialog}
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
              selectedMetric={selectedMetric}
              setSelectedMetric={setSelectedMetric}
              setSelectedUpdate={setSelectedUpdate}
            />
          )
        })}
      </YearsContainer>
      <Divider />

      <HeaderContainer
        flexDirection="column"
        justifyContent="center"
        flexGrow={1}
        flexBasis={80}
        gap="2px"
      >
        <TickerItem>{card.stock.bloomberg_code}</TickerItem>
        {card.analyst.username && ["HPR", "SVC"].includes(card.company.name) && (
          <FlexContainer>
            <Icon icon="user" />
            <AnalystNameItem>{capitalizeFirstLetter(card.analyst.username)}</AnalystNameItem>
          </FlexContainer>
        )}
        {card.company.name && (
          <FlexContainer>
            <Icon icon="third-party" />
            <CompanyNameItem>{card.company.name}</CompanyNameItem>
          </FlexContainer>
        )}
        <LastLineContainer justifyContent="space-between" alignItems="baseline">
          <MediumText>at {format(parseISO(card.model_updated_at), "h:mm aaa")}</MediumText>
          <Button small minimal icon="chart" onClick={handleClick} />
        </LastLineContainer>
      </HeaderContainer>
      {openChartDialog && (
        <ChartContainer
          openChartDialog={openChartDialog}
          setOpenChartDialog={setOpenChartDialog}
          availablePeriods={getAvailablePeriods()}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          selectedMetric={selectedMetric}
          setSelectedMetric={setSelectedMetric}
          selectedTicker={card.stock.bloomberg_code}
          selectedAnalyst={capitalizeFirstLetter(card.analyst.username)}
          selectedDatetime={`${card.model_updated_at.substring(0, 10)} at 
            ${format(parseISO(card.model_updated_at), "h:mm aaa")}`}
          updatedMetric={selectedUpdate}
          card={card}
          setSelectedUpdate={setSelectedUpdate}
        />
      )}
    </ModelUpdatesCardContainer>
  )
}

export default ModelUpdatesCard
