import { css } from "@emotion/react"
import styled from "@emotion/styled"

import { flexContainerCss, FlexContainerProps } from "./flexbox.styled"
import { SCIENT_AGGRID_COLORS } from "./scientColors"

/**
 * use this styled to build HTML table
 */

type TableContainerProps = {
  gridTemplateColumns: string
  width?: string
}

export const TableContainer = styled.table<TableContainerProps>(props => ({
  width: props.width || "100%",
  display: "grid",
  gridTemplateColumns: props.gridTemplateColumns, //Adapt to the number of columns in the table
}))

export const TheadContainer = styled.thead({
  display: "contents",
})

export const TrContainer = styled.tr({
  display: "contents",
})

export const TbodyContainer = styled.tbody({
  display: "contents",
})

/**
 * Header cell logic
 */
type HeaderCellCssProps = {
  backgroundColor?: string
  height?: string
  padding?: string | number
}

const headerCellCss = (props: HeaderCellCssProps) =>
  css({
    outline: `1px solid ${SCIENT_AGGRID_COLORS.borderColor}`, // outline is preferred to border to avoid double borders
    backgroundColor: props.backgroundColor
      ? props.backgroundColor
      : SCIENT_AGGRID_COLORS.headerBackground,
    padding: props.padding ? props.padding : "8px",
    overflow: "hidden",
    height: props.height ? props.height : "32px",
  })

// Wrapper function to set default values in flexContainerCss for HeaderCell
const headerCellFlexContainerCss = (props: FlexContainerProps) =>
  flexContainerCss({
    ...props,
    justifyContent: props.justifyContent || "center",
    alignItems: props.alignItems || "center",
  })

// Combine a th with headerCellCss and headerCellFlexContainerCss props
export const HeaderCell = styled.th<FlexContainerProps & HeaderCellCssProps>(props => [
  headerCellFlexContainerCss(props),
  headerCellCss(props),
])

/**
 * Row cell logic
 */
type RowCellCssProps = {
  backgroundColor?: string
  height?: string
  index?: number
  padding?: string | number
}

const rowCellCss = (props: RowCellCssProps) =>
  css({
    outline: `1px solid ${SCIENT_AGGRID_COLORS.borderColor}`, // outline is preferred to border to avoid double borders
    backgroundColor: props.backgroundColor
      ? props.backgroundColor
      : props.index
        ? props.index % 2 === 0 // to have differents colors one row out two
          ? SCIENT_AGGRID_COLORS.defaultRowBackground
          : SCIENT_AGGRID_COLORS.altRowBackground
        : SCIENT_AGGRID_COLORS.defaultRowBackground,
    padding: props.padding ? props.padding : "8px",
    overflow: "hidden",
    height: props.height ? props.height : "32px",
  })

// Wrapper function to set default values in flexContainerCss for HeaderCell
const rowCellFlexContainerCss = (props: FlexContainerProps) =>
  flexContainerCss({
    ...props,
    alignItems: props.alignItems || "center",
    justifyContent: props.justifyContent || "flex-start",
  })

// Combine a td with rowCellCss and rowCellFlexContainerCss props
export const RowCell = styled.td<FlexContainerProps & RowCellCssProps>(props => [
  rowCellFlexContainerCss(props),
  rowCellCss(props),
])
