import { H5 } from "@blueprintjs/core"
import styled from "@emotion/styled"
import { FlexContainer } from "../../../../styled/flexbox.styled"

export const Title = styled(H5)({
  margin: 0,
})

export const ItemsContainer = styled(FlexContainer)({
  marginLeft: "20px",
})
