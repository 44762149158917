/** @jsxImportSource @emotion/react */
import { Alert, Button, Intent } from "@blueprintjs/core"
import { Dispatch, SetStateAction, useState } from "react"

import { isEmpty } from "lodash"
import { useFormContext } from "react-hook-form"
import { FlexContainer } from "../../../../styled/flexbox.styled"
import { LargeText, MediumText, SmallText } from "../../../../styled/text.styled"
import { RiskTypeInfo } from "../Risks/enums"
import { RiskType } from "../Risks/types"
import { HeaderContainer } from "../components/Header.styled"
import { IAccountBookPairs } from "../types"
import { AdjustButton, ResetButton } from "./RebalancingHeader.styled"
import { SectorButtons } from "./components/SectorButtons"
import { RebalancingKeyMetricsTable } from "./components/RebalancingKeyMetricsTable"
import useRebalancingQuery from "./hooks/useRebalancingQuery"
import { KeyMetricsWithTargets, RebalancingType, RebalancingWithTargets } from "./types"
import { adjustPortfolio } from "./utils/adjustPortfolio"

interface IRebalancingDataHeaderProps {
  keyMetrics?: KeyMetricsWithTargets
  rebalancingInProgress: boolean
  setRebalancingInProgress: Dispatch<SetStateAction<boolean>>
  selectedFilters: IAccountBookPairs
  selectedSector: Partial<RiskType>
  setSelectedSector: Dispatch<SetStateAction<RebalancingType>>
  isLoading?: boolean
}

const RebalancingKeyMetrics = ({
  keyMetrics,
  rebalancingInProgress,
  setRebalancingInProgress,
  selectedFilters,
  selectedSector,
  setSelectedSector,
  isLoading,
}: IRebalancingDataHeaderProps) => {
  // Create state to display Alert when user wants to reset the form
  const [confirmDialog, setConfirmDialog] = useState(false)

  const sectorRebalancingInfo = RiskTypeInfo[RiskType.SECTOR_LEVEL_1]
  const SectorIcon = sectorRebalancingInfo.iconComponent

  const {
    getValues,
    reset,
    formState: { dirtyFields },
  } = useFormContext<RebalancingWithTargets>()

  const handleAdjustPortfolio = () => {
    const currentValues = getValues() // Get current portfolio state
    const adjustedValues = adjustPortfolio(currentValues) // Apply adjustments
    // Reset form with adjusted portfolio
    reset(adjustedValues)
  }

  //Get fresh imported rebalancing with targets for use to reset form with lastest rebalanced book
  const { rebalancing: importedRebalancing } = useRebalancingQuery({
    accountBookPairs: selectedFilters,
    type: selectedSector,
  })

  return (
    <HeaderContainer flexDirection="row-reverse" justifyContent="space-between">
      <FlexContainer flexDirection="column" justifyContent="space-between">
        {!isLoading && <RebalancingKeyMetricsTable keyMetrics={keyMetrics} />}
      </FlexContainer>
      <FlexContainer flexDirection="column">
        <FlexContainer style={{ height: "30%", marginTop: "30px" }} gap="25px">
          <Alert
            isOpen={confirmDialog}
            className="bp5-dark"
            canEscapeKeyCancel={true}
            canOutsideClickCancel={false}
            cancelButtonText="Cancel"
            confirmButtonText="Reset"
            icon="reset"
            intent={Intent.DANGER}
            onCancel={() => setConfirmDialog(false)}
            onClose={() => setConfirmDialog(false)}
            onConfirm={() => {
              reset(importedRebalancing)
              setRebalancingInProgress(false)
            }}
          >
            <MediumText>Are you sure you want to reset form ?</MediumText>
            <MediumText>You will lose all rebalancing in progress.</MediumText>
          </Alert>
          <ResetButton text="Reset" icon="reset" small onClick={() => setConfirmDialog(true)} />
          <AdjustButton
            text="Adjust"
            icon="calculator"
            small
            onClick={() => {
              handleAdjustPortfolio()
              setRebalancingInProgress(true)
            }}
            disabled={isEmpty(dirtyFields)}
          />
          <Button
            type="submit"
            text="Generate trade"
            icon="send-to"
            small
            intent={Intent.PRIMARY}
            disabled={!isEmpty(dirtyFields) || !rebalancingInProgress}
          />
        </FlexContainer>
      </FlexContainer>

      <FlexContainer flexDirection="column" justifyContent="space-between">
        <FlexContainer flexDirection="column" alignItems="center">
          <SmallText>Sectors</SmallText>
          <SectorIcon {...sectorRebalancingInfo.iconProps} />
          <SectorButtons
            selectedSector={selectedSector}
            setSelectedSector={setSelectedSector}
            isInProgress={!isEmpty(dirtyFields) || rebalancingInProgress}
          />
        </FlexContainer>

        <LargeText fontWeight="bold">Exposures</LargeText>
      </FlexContainer>
    </HeaderContainer>
  )
}
export default RebalancingKeyMetrics
