/** @jsxImportSource @emotion/react */
import { Spinner } from "@blueprintjs/core"

import { MediumItalicText } from "../../../../styled/text.styled"
import { DataframeData } from "../types"
import { NoDataMessage } from "./Orders.styled"
import { PositionsContainer } from "./Positions.styled"
import Grid from "../components/Grid"
import { RefreshButton } from "./components/RefreshButton"
import useIntervalDateDistance from "./hooks/useIntervalDateDistance"

interface IPositionsProps {
  positions?: DataframeData
  isLoading: boolean
}

const Positions = ({ positions, isLoading }: IPositionsProps) => {
  const positionsDateDistance = useIntervalDateDistance({
    date: positions?.last_updated_at,
    interval: 60 * 1000,
  })

  return (
    /**
     * PositionsContainer has flexGrow={1} to take all the remaining space in height.
     */
    <PositionsContainer flexGrow={1} alignItems="center" justifyContent="center">
      {positions ? (
        <Grid label="Positions" rows={positions.rows} columns={positions.columns}>
          {positionsDateDistance && (
            <>
              <MediumItalicText>Last updated {positionsDateDistance}</MediumItalicText>
              <RefreshButton dateDistance={positionsDateDistance} />
            </>
          )}
        </Grid>
      ) : isLoading ? (
        <Spinner />
      ) : (
        <NoDataMessage>
          No positions available, please select an account and/or a book
        </NoDataMessage>
      )}
    </PositionsContainer>
  )
}

export default Positions
