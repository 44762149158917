import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import jwt_decode from "jwt-decode"
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useLocation, useNavigate } from "react-router-dom"
import {
  useLocalStorage,
  useLoginMutation,
  useQueryProfile,
  useRefreshTokenMutation,
  useVerifyTokenMutation,
} from "./hooks"
import { useApi } from "./hooks/useApi"
import { createToaster } from "./utils/createToaster"
import { IUser } from "../api"

export interface IAuthContext {
  accessToken?: string
  login?: ({ username, password }: { username: string; password: string }) => void
  logout?: () => void
  authenticated: boolean
  isLoadingLogin: boolean
  otpState: OTPMachineState
  setOtpMachineState?: Dispatch<SetStateAction<OTPMachineState>>
  profile?: IUser
  isLoadingProfile: boolean
}

export interface IJWTNetadata {
  exp: number
  iat: number
  jti: string
  token_type: "access" | "refresh"
  user_id: number
}

/**
 * Enum to declare all OTP possible states
 * -- 2FA
 */
export enum OTPMachineState {
  SUGGEST_OTP_SETUP = 1,
  VERIFY_OTP_SETUP,
  SETUP_OTP,
  WAITING,
  OK,
}

/**
 * Auth context
 */
export const AuthContext = createContext<IAuthContext>({
  accessToken: undefined,
  login: undefined,
  logout: undefined,
  authenticated: false,
  isLoadingLogin: false,
  otpState: OTPMachineState.WAITING,
  setOtpMachineState: undefined,
  profile: undefined,
  isLoadingProfile: false,
})

/**
 * Interval for refresh token checks in ms
 */
const INTERVAL_REFRESH_CHECK = 1000 * 60 * 2 // 2min
const MIN_TO_MS = 60 * 1000

/**
 * Time in min under when we refresh the token
 */
const TIME_BEFORE_VALIDITY_EXPIRES = 5

/**
 * Provides Authentication capabilities to children
 */
const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const queryClient = useQueryClient()

  /**
   * State to register if the user is authenticated
   * A user is authenticated if:
   * - he logged in through login method
   * - OR if token in local Storage has been verified through verify mutation
   */
  const [authenticated, setAuthenticated] = useState(false)

  /**
   * OTP state in AuthProvider
   */
  const [otpState, setOtpMachineState] = useState<OTPMachineState>(OTPMachineState.WAITING)

  /**
   * Access and refresh token state
   */
  const [accessToken, setAccessToken] = useLocalStorage("jwt-auth-token", undefined)
  const [refreshToken, setRefreshToken] = useLocalStorage("jwt-auth-refresh-token", undefined)
  const [accessTokenMetadata, setAccessTokenMetadata] = useState<IJWTNetadata | undefined>(
    undefined,
  )

  /**
   * We need to fetch the profile here to verify that the user has set up
   * Two factor Authentication or not
   * Profile will be fetched once authenticated (Oauth2) is true
   */
  const { data: profile, isLoading: isLoadingProfile } = useQueryProfile()

  /**
   * Reference to interval set for refreshing token
   * Needed to clear Interval on rerendering
   */
  const intervalCheckRefreshRef = useRef<ReturnType<typeof setInterval> | null>(null)

  /**
   * We get the ability to register Authorization Header in
   * our queries through APIProvider
   * We also get the ability to set 2FA ready on APIProvider
   * See DOM architecture on App.tsx
   */
  const {
    registerAuthorizationHeader,
    unregisterAuthorizationHeader,
    setApi2FAVerified,
    setApiAuthenticated,
  } = useApi()
  const navigate = useNavigate()
  const location = useLocation()

  /**
   * Defines the login mutation
   */
  const { mutate: loginMutate, isLoading: isLoadingLogin } = useLoginMutation({
    onSuccess: ({ access, refresh }: { access: string; refresh: string }) => {
      /**
       * On success, access/reffresh Tokens are directly provided
       * from the server -- no need to verify the validity (i.e. not call to verify route)
       */
      setAccessTokenMetadata(jwt_decode(access))
      setAccessToken(access)
      setRefreshToken(refresh)
      setAuthenticated(true)
    },
    onError: (error: AxiosError) => {
      createToaster({
        message: `Incorrect credentials ${error}`,
        intent: "danger",
      })
      setAccessTokenMetadata(undefined)
      setAccessToken(undefined)
      setRefreshToken(undefined)
      setAuthenticated(false)
      setApiAuthenticated && setApiAuthenticated(false)
      setApi2FAVerified && setApi2FAVerified(false)
    },
  })

  const { mutate: refreshTokenMutate } = useRefreshTokenMutation({
    onSuccess: ({ access, refresh }: { access: string; refresh: string }) => {
      setAccessTokenMetadata(jwt_decode(access))
      setAccessToken(access)
      setRefreshToken(refresh)
      setAuthenticated(true)
      registerAuthorizationHeader && registerAuthorizationHeader(access)
      queryClient.invalidateQueries({ queryKey: ["profile"] })
    },
    onError: () => {
      createToaster({
        message: `An error occurred checking authorizations - Please reconnect`,
        intent: "danger",
      })
      setAccessTokenMetadata(undefined)
      setAccessToken(undefined)
      setRefreshToken(undefined)
      setAuthenticated(false)
      setApiAuthenticated && setApiAuthenticated(false)
      setApi2FAVerified && setApi2FAVerified(false)
    },
  })

  /**
   * Defines the verify token mutation
   */
  const { mutate: verifyTokenMutate } = useVerifyTokenMutation({
    onSuccess: () => {
      /**
       * In case token will expire, we do not set authenticated
       * as a new token will be available
       */
      const tokenMetadata = jwt_decode<IJWTNetadata>(accessToken)
      setAccessTokenMetadata(tokenMetadata)
      if (!willExpire(tokenMetadata)) {
        setAuthenticated(true)
      }
    },
    onError: (error: AxiosError) => {
      /**
       * If status is 401/403, it means that access token has expired, refresh will happen through interval
       * Otherwise, it means server error or client connection lost -> we ask for refresh
       */
      if ([401, 403].includes(error.request.status)) {
        refreshTokenMutate({ refresh: refreshToken })
      } else {
        createToaster({
          message: `An error occurred checking authorization - Please refresh the page`,
          intent: "danger",
        })
        setAccessTokenMetadata(undefined)
        setAccessToken(undefined)
        setRefreshToken(undefined)
        setAuthenticated(false)
        setApiAuthenticated && setApiAuthenticated(false)
        setApi2FAVerified && setApi2FAVerified(false)
      }
    },
  })

  /**
   * Set Cookie for image authentication on AWS CDN
   */
  const setCookie = useCallback(() => {
    if (process.env.NODE_ENV === "production" && authenticated) {
      const expireDate = new Date()

      /**
       * Expires in 100 days
       */
      const expireTime = expireDate.getTime() + 100 * 24 * 3600 * 1000
      expireDate.setTime(expireTime)
      document.cookie = `token=${accessToken};domain=scient.io;path=/;expires=${expireDate.toUTCString()}; Secure`
    }
  }, [authenticated, accessToken])

  /**
   * Login method provided to Children
   */
  const login = useCallback(
    ({ username, password }: { username: string; password: string }) => {
      loginMutate({ username, password })
    },
    [loginMutate],
  )

  /**
   * In case access token is in local Storage, we do not
   * ask the server for a new token but we verify its validity
   * through verify route
   */
  useEffect(() => {
    if (!!accessToken && !authenticated) {
      verifyTokenMutate({ token: accessToken })
    }
  }, [accessToken, authenticated, verifyTokenMutate])

  /**
   * If state is authenticated and that we tried to reach a route
   * prior to login, we redirect there.
   * If not, Login Form will redirect to HOME
   */
  useEffect(() => {
    if (authenticated && registerAuthorizationHeader) {
      /**
       * Register Authorization Header for all queries
       * this sets APIProvider state to authenticated through Oauth2
       */
      registerAuthorizationHeader(accessToken)
      if (otpState === OTPMachineState.OK) {
        const origin = location.state?.from?.pathname
        const search = location.state?.from?.search
        const hash = location.state?.from?.hash
        if (origin) {
          navigate(origin + search + hash)
        }
      }
      setCookie()
    }
  }, [
    accessToken,
    authenticated,
    location,
    navigate,
    otpState,
    registerAuthorizationHeader,
    setCookie,
  ])

  /**
   * Logout method provided to children
   */
  const logout = useCallback(() => {
    setAuthenticated(false)
    setAccessToken(undefined)
    setRefreshToken(undefined)
    setAccessTokenMetadata(undefined)
    unregisterAuthorizationHeader && unregisterAuthorizationHeader()
    queryClient.clear()
    setOtpMachineState(OTPMachineState.WAITING)
    setApiAuthenticated && setApiAuthenticated(false)
    setApi2FAVerified && setApi2FAVerified(false)

    /**
     * Disable Intercom on logout
     */
    if (process.env.REACT_APP_INTERCOM === "true") {
      // @ts-ignore
      window.Intercom("shutdown")
    }
  }, [
    setAccessToken,
    setRefreshToken,
    unregisterAuthorizationHeader,
    queryClient,
    setApiAuthenticated,
    setApi2FAVerified,
  ])

  const willExpire = useCallback((accessTokenMetadata: IJWTNetadata) => {
    const expire_at = new Date(accessTokenMetadata.exp * 1000)
    const now = new Date()
    const time_diff_in_min = (expire_at.getTime() - now.getTime()) / MIN_TO_MS
    return time_diff_in_min < TIME_BEFORE_VALIDITY_EXPIRES
  }, [])

  /**
   * Checks when access token will expire
   * and request the server for a new token if under TIME_BEFORE_VALIDITY_EXPIRES
   */
  const refreshOnTokenExpiry = useCallback(
    (accessTokenMetadata: IJWTNetadata) => {
      if (willExpire(accessTokenMetadata)) {
        refreshTokenMutate({ refresh: refreshToken })
      }
    },
    [refreshToken, refreshTokenMutate, willExpire],
  )

  /**
   * Handles interval checks for token refresh
   */
  useEffect(() => {
    if (accessTokenMetadata) {
      refreshOnTokenExpiry(accessTokenMetadata)
      intervalCheckRefreshRef.current = setInterval(
        () => refreshOnTokenExpiry(accessTokenMetadata),
        INTERVAL_REFRESH_CHECK,
      )
    }
    return () => {
      if (intervalCheckRefreshRef.current) {
        clearInterval(intervalCheckRefreshRef.current)
      }
    }
  }, [accessTokenMetadata, refreshOnTokenExpiry])

  /**
   * OTP machine state logic
   */
  useEffect(() => {
    /**
     * If profile is loaded, we can check OTP settings
     */
    if (profile) {
      /**
       * Once authenticated and profile is loaded
       * we check if user has set up 2FA
       */
      if (authenticated) {
        /**
         * If OTP is enabled, checks verification
         */
        if (profile?.otp?.otp_enabled) {
          /**
           * If otp is already verified, set last state OK
           */
          if (profile?.otp?.verified) {
            setOtpMachineState(OTPMachineState.OK)
            setApi2FAVerified && setApi2FAVerified(true)
          } else {
            /**
             * If otp is not verified, show OTP verification
             */
            setOtpMachineState && setOtpMachineState(OTPMachineState.VERIFY_OTP_SETUP)
          }
        } else {
          /**
           * If OTP is not enabled, go either to suggestion
           * to set up OTP if two factor auth is not enforced
           */
          if (process.env.REACT_APP_ENFORCE_TWO_FACTOR_AUTH === "false") {
            setOtpMachineState && setOtpMachineState(OTPMachineState.SUGGEST_OTP_SETUP)
          } else {
            setOtpMachineState && setOtpMachineState(OTPMachineState.SETUP_OTP)
          }
        }
      }
    }
  }, [authenticated, profile, profile?.otp?.otp_enabled, profile?.otp?.verified, setApi2FAVerified])

  /**
   * Memoized state for context change
   * and children prop propagation
   */
  const state = useMemo(
    () => ({
      accessToken,
      authenticated,
      login,
      logout,
      // Used to avoid multiple logins attempts in chains in Login Form
      isLoadingLogin,
      otpState,
      setOtpMachineState,
      profile,
      isLoadingProfile,
    }),
    [
      accessToken,
      authenticated,
      login,
      logout,
      isLoadingLogin,
      otpState,
      profile,
      isLoadingProfile,
    ],
  )

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider
